import {
    DragEvent,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { format, parseISO } from 'date-fns';

import {
    useCreateResourceFileMutation,
    useDeleteResourceFileMutation,
    useDeleteResourceFolderMutation,
    useGetResourceFolderQuery,
    useLazyGetResourceFoldersQuery,
} from '../../api/resources';

import { formatFileSize, getEntityFromParam } from '../../util/helper';

import useFileUpload from '../../hooks/useFileUpload';

import FolderForm from './FolderForm';
import FolderBreadCrumbs from './FolderBreadCrumbs';

import Icon from '../../components/Icon';
import RookieButton from '../../components/RookieButton';
import StaffCell from '../../components/StaffCell';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable, DataTableRowClickEvent } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { Menu } from 'primereact/menu';
import { Toolbar } from 'primereact/toolbar';

import { BaseEntityType } from '../../types/common';
import { ResourceFile, ResourceFolder } from '../../types/resources';
import { useAuth0 } from '@auth0/auth0-react';
import { ToastContext } from '../../contexts/ToastContext';
import { ToastMessage } from 'primereact/toast';

interface TableData {
    type: 'folder' | 'resource';
    id: string;
    name: string;
    createdAt: string;
    createdBy: string;
    fileSize: string | null;
    lastEditedBy: string;
    lastEdited: string;
    data: ResourceFile | ResourceFolder;
}

const DISALLOWED_EXTENSIONS = ['.zip', '.exe', '.bat', '.sh', '.cmd'];
const MAX_FILE_SIZE = 50 * 1024 * 1024; // 50 MB
const MAX_SIZE_STRING = '50MB';

const ResourceFiles = () => {
    const params = useParams();
    const location = useLocation();
    const navigate = useNavigate();

    const { getIdTokenClaims } = useAuth0();

    const activeEntity = useMemo(() => getEntityFromParam(params), [params]);

    const menuRef = useRef<Menu>(null);
    const fileInputRef = useRef<HTMLInputElement | null>(null);

    const toast = useContext(ToastContext);

    const [selection, setSelection] = useState<TableData | null>(null);
    const [isDraggingOver, setIsDraggingOver] = useState(false);
    const [uploading, setUploading] = useState(false);
    const [showFolderForm, setShowFolderForm] = useState(false);

    const [fetchRootFolder] = useLazyGetResourceFoldersQuery();
    const [deleteFolder] = useDeleteResourceFolderMutation();
    const [createPresignedURL] = useCreateResourceFileMutation();
    const [deleteFile] = useDeleteResourceFileMutation();

    const { data, isLoading, isFetching } = useGetResourceFolderQuery(
        {
            entityID: activeEntity?.entityID as string,
            entityType: activeEntity?.entityType as BaseEntityType,
            folderID: params.folderID as string,
            expand: 'breadcrumbs',
        },
        {
            skip: !activeEntity || !params.folderID,
        }
    );

    const { uploadToPresignedUrl } = useFileUpload({
        entityType: activeEntity?.entityType as BaseEntityType,
        entityID: activeEntity?.entityID as string,
    });

    const showToast = (toastOptions: ToastMessage) => {
        if (toast && toast.current) {
            toast.current.show(toastOptions);
        }
    };

    useEffect(() => {
        if (!params.folderID) {
            fetchRootFolder({
                entityType: activeEntity?.entityType as BaseEntityType,
                entityID: activeEntity?.entityID as string,
            })
                .then((response) => {
                    if (response?.data?.data.folderID) {
                        navigate(`${response.data.data.folderID}`);
                    }
                })
                .catch((error) => {
                    console.error(error);
                });
        }
    }, [activeEntity, params.folderID, fetchRootFolder, navigate]);

    const tableData = useMemo(() => {
        const children = data?.data.children;
        let newData: TableData[] = [];

        if (children) {
            children.folders.forEach((folder) => {
                newData.push({
                    type: 'folder',
                    id: folder.folderID,
                    name: folder.folderName,
                    createdAt: folder.createdAt,
                    createdBy: folder.createdBy,
                    fileSize: null,
                    lastEditedBy: folder.lastEditedBy,
                    lastEdited: folder.lastEdited,
                    data: folder,
                });
            });

            children.resources.forEach((resource) => {
                newData.push({
                    type: 'resource',
                    id: resource.fileID,
                    name: resource.resourceName,
                    createdAt: resource.createdAt,
                    createdBy: resource.createdBy,
                    fileSize: resource.fileSize,
                    lastEditedBy: resource.lastEditedBy,
                    lastEdited: resource.lastEdited,
                    data: resource,
                });
            });
        }
        return newData;
    }, [data]);

    const navigateToFolder = (folderID: string) => {
        if (params.folderID) {
            const folderURL = location.pathname.replace(
                params.folderID,
                folderID
            );

            navigate(`${folderURL}${location.search}`);

            setSelection(null);
        }
    };

    const handleRowDoubleClick = ({ data }: DataTableRowClickEvent) => {
        if (data) {
            if (data.type === 'folder') {
                navigateToFolder(data.id);
            }
            if (data.type === 'resource' && 'resourceURL' in data.data) {
                handleDownloadFile(data.data.resourceURL, data.name);
            }
        }
    };

    const handleDelete = (resource: TableData | null) => {
        if (!resource) return;

        if (resource.type === 'resource') {
            deleteFile({
                entityType: activeEntity?.entityType as BaseEntityType,
                entityID: activeEntity?.entityID as string,
                resourceID: resource.id,
            })
                .then(() => {
                    showToast({
                        severity: 'success',
                        detail: `${resource.name} has been deleted.`,
                        summary: 'Success',
                    });
                })
                .catch((error) => {
                    showToast({
                        severity: 'error',
                        detail: `There was an error deleting ${resource.name}. Please try again.`,
                        summary: 'Error',
                    });
                    console.error(error);
                });
        }

        if (resource.type === 'folder') {
            deleteFolder({
                entityType: activeEntity?.entityType as BaseEntityType,
                entityID: activeEntity?.entityID as string,
                folderID: resource.id,
            })
                .then(() => {
                    showToast({
                        severity: 'success',
                        detail: `${resource.name} has been deleted.`,
                        summary: 'Success',
                    });
                })
                .catch((error) => {
                    showToast({
                        severity: 'error',
                        detail: `There was an error deleting ${resource.name}. Please try again.`,
                        summary: 'Error',
                    });
                    console.error(error);
                });
            return;
        }
    };

    const uploadResource = async (
        presignedUrl: string,
        file: File
    ): Promise<void> => {
        try {
            await uploadToPresignedUrl(presignedUrl, file);

            toast?.current?.replace({
                severity: 'success',
                detail: `${file.name} has been uploaded.`,
                summary: 'Success',
            });
        } catch (error) {
            toast?.current?.replace({
                severity: 'error',
                detail: `There was an error uploading ${file.name}. Please try again.`,
                summary: 'Error',
            });
            console.error(error);
        }
    };

    const handleUploadChange = async (files: FileList) => {
        if (!activeEntity || !params.folderID) {
            console.error('No entity or parent folder provided');
            return;
        }

        const file = files[0];

        // Check if file has been selected
        if (!file) {
            console.warn('No file selected');
            return;
        }

        // Validate file extension
        const fileExtension = file.name
            .slice(file.name.lastIndexOf('.'))
            .toLowerCase();

        if (DISALLOWED_EXTENSIONS.includes(fileExtension)) {
            console.warn(
                `Files with extensions ${DISALLOWED_EXTENSIONS.join(
                    ', '
                )} are not allowed.`
            );
            showToast({
                severity: 'error',
                detail: `Files with extensions ${DISALLOWED_EXTENSIONS.join(
                    ', '
                )} are not allowed.`,
                closable: true,
            });
            return;
        }

        // Validate file size
        if (file.size > MAX_FILE_SIZE) {
            console.warn(
                `File size exceeds the limit of ${MAX_FILE_SIZE} bytes.`
            );
            showToast({
                severity: 'error',
                detail: `File size exceeds the limit of ${MAX_SIZE_STRING}.`,
                closable: true,
            });
            return;
        }

        setUploading(true);

        showToast({
            severity: 'info',
            detail: `File uploading...`,
            closable: false,
        });

        try {
            // Get the presigned url to attach the file
            const response = await createPresignedURL({
                entityType: activeEntity.entityType,
                entityID: activeEntity.entityID,
                parentID: params.folderID,
                resourceName: file.name,
                resourceDescription: '',
            });

            if ('error' in response) {
                toast?.current?.replace({
                    severity: 'error',
                    detail: `There was an error uploading ${file.name}. Please try again.`,
                    summary: 'Error',
                });
                console.error('ERROR CREATING PRESIGNED URL', response.error);
                return;
            }

            const presignedUrl = response.data.data?.putPresignedUrl;

            if (!presignedUrl) {
                toast?.current?.replace({
                    severity: 'error',
                    detail: `There was an error uploading ${file.name}. Please try again.`,
                    summary: 'Error',
                });
                console.error('NO PRESIGNED URL AVAILABLE');
                return;
            }

            // Upload file using presigned URL
            await uploadResource(presignedUrl, file);
        } catch (error) {
            toast?.current?.replace({
                severity: 'error',
                detail: `There was an error uploading ${file.name}. Please try again.`,
                summary: 'Error',
            });
        } finally {
            setUploading(false);
        }
    };

    const handleUploadClick = () => {
        fileInputRef.current?.click();
    };

    const menu = [
        ...(selection?.type === 'folder'
            ? [
                  {
                      label: 'View Folder',
                      command: () => navigateToFolder(selection.id),
                      icon: 'arrow_forward',
                  },
                  {
                      label: 'Rename',
                      command: () => setShowFolderForm(true),
                      icon: 'edit',
                  },
              ]
            : []),
        ...(selection?.type === 'resource'
            ? [
                  {
                      label: 'Download',
                      command: () => {
                          if ('resourceURL' in selection.data) {
                              return handleDownloadFile(
                                  selection.data.resourceURL,
                                  selection.name
                              );
                          }
                      },
                      icon: 'download',
                  },
              ]
            : []),
        {
            label: 'Delete',
            command: () => handleDelete(selection),
            icon: 'delete',
        },
    ];

    const ToolbarSelection = selection ? (
        <div className="p-button-group">
            <RookieButton
                icon="close"
                text
                onClick={() => setSelection(null)}
                size="small"
            />
            <p>1 selected</p>
            {menu.map((option) => (
                <RookieButton
                    text
                    icon={option.icon}
                    label={option.label}
                    onClick={option.command}
                    size="small"
                />
            ))}
        </div>
    ) : (
        data?.data.breadcrumbs &&
        data?.data.breadcrumbs.length > 0 && (
            <FolderBreadCrumbs
                crumbs={[...data.data.breadcrumbs, data.data]}
                onClick={(folderID: string) => navigateToFolder(folderID)}
            />
        )
    );

    const handleDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        event.stopPropagation();

        setIsDraggingOver(false);
    };

    const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        event.stopPropagation();

        setIsDraggingOver(true);
    };

    const handleDrop = (e: DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();

        setIsDraggingOver(false);

        handleUploadChange(e.dataTransfer.files);
    };

    const handleDownloadFile = async (fileUrl: string, fileName: string) => {
        const token = await getIdTokenClaims();

        try {
            // Fetch the file
            const response = await fetch(fileUrl, {
                headers: {
                    Authorization: `Bearer ${token?.__raw}`,
                },
            });

            if (!response.ok) {
                throw new Error('Failed to fetch the file');
            }

            // Get the file as a Blob
            const blob = await response.blob();

            // Create a URL for the Blob
            const url = window.URL.createObjectURL(blob);

            // Create a temporary anchor element
            const link = document.createElement('a');
            link.href = url;

            // Set the file name for download (you can customize this)
            link.download = fileName;

            // Append the link to the document and trigger the download
            document.body.appendChild(link);
            link.click();

            // Cleanup
            link.parentNode?.removeChild(link);
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error('Error downloading the file:', error);
        }
    };

    const getFileIcon = (resource: TableData) => {
        if (resource.type === 'folder') {
            return 'folder';
        }

        if ('contentType' in resource.data) {
            const contentType = resource.data.contentType;

            // Map of MIME types to icons
            const mimeTypeMap: { [key: string]: string } = {
                'document/xlsx': 'table',
                'document/csv': 'table',
                'text/csv': 'table',
                'text/plain': 'docs',
                'application/zip': 'folder_zip',
                'application/x-rar-compressed': 'folder_zip',
            };

            // Fallback based on general types (e.g., 'audio', 'video', etc.)
            const generalTypeMap: { [key: string]: string } = {
                document: 'draft',
                image: 'image',
                video: 'video_file',
                audio: 'audio_file',
                text: 'docs',
                application: 'folder_zip',
            };

            // Match specific MIME type first
            if (mimeTypeMap[contentType]) {
                return mimeTypeMap[contentType];
            }

            // Fallback to general type (e.g., 'audio', 'video', etc.)
            const generalType = contentType.split('/')[0];
            if (generalTypeMap[generalType]) {
                return generalTypeMap[generalType];
            }
        }

        // Default fallback icon
        return 'draft';
    };

    return (
        <>
            <Toolbar
                start={ToolbarSelection}
                end={
                    <div className="p-button-group">
                        <input
                            ref={fileInputRef}
                            type="file"
                            onChange={(e) =>
                                e.target.files &&
                                handleUploadChange(e.target.files)
                            }
                            style={{ display: 'none' }}
                        />
                        <RookieButton
                            type="button"
                            icon="upload"
                            label="Upload"
                            onClick={handleUploadClick}
                            loading={uploading}
                        />
                        <Button
                            onClick={() => setShowFolderForm(true)}
                            label="New Folder"
                        />
                    </div>
                }
                style={{ border: 'none', padding: 0 }}
            />

            <div
                className={`drop-zone ${isDraggingOver ? 'is-dragging' : ''}`}
                onDrop={handleDrop}
                onDragOver={handleDragOver}
                onDragLeave={handleDragLeave}
            >
                <DataTable
                    value={tableData}
                    loading={isLoading || isFetching}
                    onRowDoubleClick={handleRowDoubleClick}
                    onSelectionChange={(e) =>
                        setSelection(e.value as TableData)
                    }
                    selectionMode="single"
                    selection={selection}
                >
                    <Column
                        header="Name"
                        field="name"
                        sortable
                        body={(data) => {
                            const icon = getFileIcon(data);

                            return (
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: '1rem',
                                    }}
                                >
                                    <Icon
                                        name={icon}
                                        fill={data.type === 'resource'}
                                    />
                                    <span>{data.name}</span>
                                </div>
                            );
                        }}
                    />
                    <Column
                        header="Owner"
                        field="createdBy"
                        body={(row) => {
                            if (activeEntity) {
                                return (
                                    <StaffCell
                                        userID={row.createdBy}
                                        entityID={activeEntity.entityID}
                                        entityType={activeEntity.entityType}
                                    />
                                );
                            }
                        }}
                    />
                    <Column
                        header="Last Edited"
                        field="lastEdited"
                        sortable
                        body={(data) =>
                            format(parseISO(data.lastEdited), 'dd/MM/yyyy')
                        }
                        headerStyle={{ maxWidth: '100px' }}
                        bodyStyle={{ maxWidth: '100px' }}
                    />
                    <Column
                        header="File Size"
                        field="fileSize"
                        sortable
                        body={(row) =>
                            row.fileSize && formatFileSize(row.fileSize)
                        }
                        headerStyle={{ maxWidth: '100px' }}
                        bodyStyle={{ maxWidth: '100px' }}
                    />
                    <Column
                        header=""
                        align="right"
                        headerStyle={{ maxWidth: '50px' }}
                        bodyStyle={{ maxWidth: '50px' }}
                        body={(row) => {
                            return (
                                <RookieButton
                                    icon="more_vert"
                                    severity="secondary"
                                    text
                                    onClick={(e) => {
                                        setSelection(row);
                                        menuRef.current?.toggle(e);
                                    }}
                                    tooltip="More"
                                    tooltipOptions={{ position: 'top' }}
                                />
                            );
                        }}
                    />
                </DataTable>
            </div>

            <Menu model={menu} popup ref={menuRef} />

            {activeEntity && (
                <Dialog
                    onHide={() => {
                        setShowFolderForm(false);
                        setSelection(null);
                    }}
                    visible={showFolderForm}
                    header={selection ? 'Rename' : 'New Folder'}
                    resizable={false}
                    draggable={false}
                >
                    <FolderForm
                        folder={
                            selection
                                ? data?.data.children?.folders.find(
                                      (f) => f.folderID === selection.id
                                  )
                                : undefined
                        }
                        parentFolderID={params.folderID}
                        entityType={activeEntity.entityType}
                        entityID={activeEntity.entityID}
                        onSuccess={() => setShowFolderForm(false)}
                    />
                </Dialog>
            )}
        </>
    );
};

export default ResourceFiles;
