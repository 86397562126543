import { useEffect, useMemo, useRef, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import {
    reduce,
    unionBy,
    uniq,
    groupBy,
    map,
    intersectionWith,
    union,
    uniqBy,
    sortBy,
} from 'lodash';

import { useGetEventsQuery } from '../../api/events';
import {
    useGetTeamOwnedSeasonsQuery,
    useGetTeamParticipatingSeasonsQuery,
} from '../../api/seasons';
import {
    useGetTeamSeasonStatsBasicReportQuery,
    useGetTeamSeasonStatsAdvancedReportQuery,
} from '../../api/reports';

import PageHeader from '../../layout/PageHeader';
import playLogo from '../../assets/images/logos/rm-play-logo.png';
import { Image } from 'primereact/image';

import { Column } from 'primereact/column';
import { periodSuffix } from '../../util/helper';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { SelectButton } from 'primereact/selectbutton';
import { SelectItem } from 'primereact/selectitem';
import { Skeleton } from 'primereact/skeleton';
import { Toolbar } from 'primereact/toolbar';

import { columns, config } from './configStats';
import { toISOStringWithTimezone } from '../../util/helper';
import { defaultReportState } from '../reports/constants';

import { Event } from '../../types/event';
import { ReportDataTypes, ReportState, TeamStat } from '../../types/reports';

import { useLazyGetPlayersQuery } from '../../api/players';
import { useGetTeamQuery } from '../../api/teams';
import { playerGroupCell, playerNameCell } from '../reports/DataTableCells';
import { useGetStatsQuery } from '../../api/stats';
import { InputSwitch } from 'primereact/inputswitch';
import { Tooltip } from 'primereact/tooltip';
import Icon from '../../components/Icon';
import ErrorDisplay from '../../components/ErrorDisplay';
import { BaseEntityType, ERROR_TYPES } from '../../types/common';
import { StatConfig } from '../../types/sports';
import RookieButton from '../../components/RookieButton';
import PageContainer from '../../layout/PageContainer';
import { getScoringAccuracyColumn } from './helpers';
import { Mixpanel } from '../../util/mixpanel';
import { Player } from '../../types/team';

interface Props {
    reportType: string;
}

const PlayerStatsReport = ({ reportType }: Props) => {
    // Route Params
    const { teamID } = useParams();

    // Search Params
    const [searchParams] = useSearchParams();
    const eventParam = searchParams.get('eventID');
    const seasonParam = searchParams.get('seasonID');
    const categoryParam = searchParams.get('category');

    // State Hooks
    const [dataType, setDataType] = useState(ReportDataTypes.Total);
    const [season, setSeason] = useState<string | null>(seasonParam || null);
    const [event, setEvent] = useState(eventParam || '');
    const [category, setCategory] = useState(categoryParam || '');
    const [showInfo, setShowInfo] = useState<boolean>(false);
    const [hideEmptyColumns, setHideEmptyColumns] = useState(true);
    const [reportData, setReportData] =
        useState<ReportState<TeamStat[]>>(defaultReportState);

    const [players, setPlayers] = useState<Player[]>([]);

    const [filter, setFilter] = useState(0); // for period filter

    // Cache busting ref
    const timestampRef = useRef(Date.now()).current;
    const dataTable = useRef<DataTable<any>>(null);

    const reportParams = {
        seasonID: season || '',
        teamID: teamID || '',
        sessionID: timestampRef,
    };

    const teamData = useGetTeamQuery(
        { teamID: teamID || '' },
        { skip: !teamID }
    );
    const team = teamData?.data?.data;

    // API Hooks
    const [fetchPlayers, { originalArgs: playerArgs }] =
        useLazyGetPlayersQuery();

    const requestBasicReport = useGetTeamSeasonStatsBasicReportQuery(
        reportParams,
        {
            skip: !season || !teamID || reportType !== 'basic',
        }
    );
    const requestAdvancedReport = useGetTeamSeasonStatsAdvancedReportQuery(
        reportParams,
        {
            skip: !season || !teamID || reportType !== 'advanced',
        }
    );

    const requestReportData =
        reportType === 'advanced' ? requestAdvancedReport : requestBasicReport;

    const seasonOwnedData = useGetTeamOwnedSeasonsQuery(
        {
            teamID: teamID || '',
            cursor: '',
        },
        { skip: !teamID }
    );

    const seasonParticipatingData = useGetTeamParticipatingSeasonsQuery(
        {
            teamID: teamID || '',
            cursor: '',
        },
        { skip: !teamID }
    );

    const statConfigData = useGetStatsQuery({
        sportID: team?.entitySportID as string,
    });

    const eventData = useGetEventsQuery({
        entityType: BaseEntityType.teams,
        entityID: teamID || '',
        to: toISOStringWithTimezone(new Date()),
        limit: '50',
    });

    const loadPlayers = (cursor: string, status = 'Active') => {
        if (teamID) {
            fetchPlayers(
                {
                    cursor,
                    status,
                    teamID,
                },
                true
            )
                .then((response) => {
                    if (response.data) {
                        if (response?.data?.data) {
                            const d = response.data?.data;

                            setPlayers((prev) => [...prev, ...d]);
                        }

                        // Auto paginate teams
                        if (
                            response.data.lastEvaluatedKey.cursor &&
                            response.data.lastEvaluatedKey.cursor !== cursor
                        ) {
                            loadPlayers(response.data.lastEvaluatedKey.cursor);
                        }
                    }
                })
                .catch((error) => {
                    console.error(
                        `Error fetching events for team ${teamID}`,
                        error
                    );
                });
        }
    };

    // Fetch Active Players
    useEffect(() => {
        loadPlayers('');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Fetch Archived Players if required
    useEffect(() => {
        if (
            !reportData.isUninitialized &&
            !reportData.isSuccess &&
            playerArgs?.status === 'Active'
        ) {
            const hasMissingPlayers = reportData.data?.some(
                (stat: any) => !stat.player
            );

            if (hasMissingPlayers) {
                loadPlayers('', 'Archived');
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reportData, playerArgs]);

    useEffect(() => {
        if (players && reportData.data) {
            setReportData((prev) => ({
                ...prev,
                data:
                    prev.data &&
                    prev.data.map((stat: any) => ({
                        ...stat,
                        player: players.find(
                            (p) => p.playerID === stat.playerID
                        ),
                    })),
            }));
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [players, reportData.isLoading]);

    // Helpers
    const statConfig = statConfigData?.data?.data;

    const rawData = reportData?.data;

    const mergedSeasons = useMemo(() => {
        const ownedSeasons = seasonOwnedData?.data?.data;
        const participatingSeasons = seasonParticipatingData?.data?.data;

        return ownedSeasons && participatingSeasons
            ? unionBy(ownedSeasons, participatingSeasons, 'seasonID')
            : [];
    }, [seasonOwnedData, seasonParticipatingData]);

    // Filter Options
    const eventOptions = useMemo(() => {
        let options = [
            {
                label: 'Entire Season',
                value: '',
                error: false,
            },
        ];

        const availableGames = uniq(
            reportData?.data?.map((val: TeamStat) => val.eventID)
        );

        if (eventData.data) {
            eventData.data.data.forEach((event: Event) => {
                options.push({
                    label: event.eventName,
                    value: event.eventID,
                    error: !availableGames.includes(event.eventID),
                });
            });
        }

        // Filter out options where error is true
        return options.filter((option) => !option.error);
    }, [reportData, eventData]);

    const totalEventsCount = useMemo(() => {
        return uniq(reportData?.data?.map((o: TeamStat) => o.eventID)).length;
    }, [reportData]);

    const getAverages = (data: TeamStat[]) => {
        return map(groupBy(data, 'playerID'), (val, key) => {
            const totals: { [key: string]: any } = reduce(
                val,
                (prev, curr) => {
                    Object.keys(curr).forEach((k) => {
                        if (curr.hasOwnProperty(k)) {
                            prev[k] =
                                k !== 'totalPGT' && typeof curr[k] === 'number'
                                    ? (prev[k] || 0) + curr[k]
                                    : curr[k];
                        }
                    });
                    return prev;
                },
                {} as { [key: string]: TeamStat }
            );

            let averages: { [key: string]: any } = {};
            Object.keys(totals).forEach((k) => {
                averages[k] =
                    typeof totals[k] === 'number'
                        ? totals[k] / totalEventsCount
                        : totals[k];
            });

            return averages;
        });
    };

    const getTotals = (data: TeamStat[]) => {
        return map(groupBy(data, 'playerID'), (val, key) => {
            return reduce(
                val,
                (prev, curr) => {
                    Object.keys(curr).forEach((k) => {
                        if (curr.hasOwnProperty(k)) {
                            prev[k] =
                                k !== 'totalPGT' && typeof curr[k] === 'number'
                                    ? (prev[k] || 0) + curr[k]
                                    : curr[k];
                        }
                    });
                    return prev;
                },
                {} as { [key: string]: TeamStat }
            );
        });
    };

    // Request stat data
    useEffect(() => {
        const reportUrl = requestReportData?.data?.data.objectURL;

        if (reportUrl && requestReportData.isSuccess) {
            setReportData((prev) => ({
                ...prev,
                data: undefined,
                error: undefined,
                isError: false,
                isLoading: true,
                isUninitialized: false,
            }));

            //fetch report
            fetch(reportUrl)
                .then((response) => response.json())
                .then((data) => {
                    setReportData((prev) => ({
                        ...prev,
                        data,
                        isError: false,
                        isLoading: false,
                    }));
                })
                .catch((err) => {
                    setReportData((prev) => ({
                        ...prev,
                        error: err,
                        isError: true,
                        isLoading: false,
                    }));
                });
        } else {
            setReportData(defaultReportState);
        }
    }, [requestReportData]);

    const categoryOptions = useMemo(() => {
        let options: SelectItem[] = [
            {
                label: 'All Categories',
                value: '',
            },
        ];

        const uniqStats = union(
            ...(rawData || []).map((d: TeamStat) => Object.keys(d))
        );
        const uniqCategories = uniqBy(
            intersectionWith(statConfig, uniqStats, (config, statID) => {
                return config.statID === statID;
            }),
            'statCategory.name'
        );

        const sortedCategories = sortBy(
            uniqCategories,
            'statCategory.sortOrder'
        );

        sortedCategories.forEach((config) => {
            options.push({
                label: config.statCategory.name,
                value: config.statCategory.name,
            });
        });

        return options;
    }, [rawData, statConfig]);

    const seasonOptions = useMemo(() => {
        let options = mergedSeasons
            ? mergedSeasons.map((season) => ({
                  label: season.seasonName,
                  value: season.seasonID,
              }))
            : [];

        return options;
    }, [mergedSeasons]);

    const dataTypeOptions = [
        {
            label: ReportDataTypes.Total,
            value: ReportDataTypes.Total,
        },
        {
            label: ReportDataTypes.Average,
            value: ReportDataTypes.Average,
        },
    ];

    // Set the season to the first available season
    useEffect(() => {
        if (!season && mergedSeasons.length > 0) {
            setSeason(team?.defaultSeasonID || '');
        }
    }, [mergedSeasons, season, team]);

    // Set data type to totals when filtering by a single event
    useEffect(() => {
        if (event !== '' && dataType === ReportDataTypes.Average) {
            setDataType(ReportDataTypes.Total);
        }
    }, [event, dataType]);

    const exportCSV = () => {
        dataTable.current && dataTable.current.exportCSV();

        Mixpanel.track('Export Report', {
            reportType: `${reportType} Cumulative Player Game Stats`,
        });
    };

    const filteredData = useMemo(() => {
        // Merge stats and plus minus report
        let filtered = reportData.data || [];

        // Apply category filter
        if (event !== '') {
            filtered = filtered.filter((item) => item.eventID === event);
        }

        filtered = filtered.filter(
            (item: TeamStat) => filter === 0 || Number(item.period) === filter
        );

        // Remove the total to avoid summing both the individual periods and the total
        filtered = filtered.filter(
            (o) =>
                o.period !== 'total' &&
                o.playerID !== 'team' &&
                o.playerID !== 'opposition'
        );

        const sorted: TeamStat[] = sortBy(
            filtered,
            (item) => item.player && Number(item.player.uniformNumber)
        );

        return sorted;
    }, [reportData, event, filter]); // Recalculate when rawData or filter changes

    let totalPeriods = 0;
    let periodFilteredData: TeamStat[] = reportData.data || [];
    if (event !== '') {
        const filteredData = periodFilteredData.filter(
            (item: TeamStat) => item.eventID === event
        );

        totalPeriods = filteredData.reduce((maxPeriod, item: TeamStat) => {
            const itemPeriod =
                typeof item.period === 'string'
                    ? parseInt(item.period, 10)
                    : item.period;
            return itemPeriod > maxPeriod ? itemPeriod : maxPeriod;
        }, 0);
    }

    const periodOptions = useMemo(
        () =>
            Array.from({ length: totalPeriods }).map((u, i) => {
                const period = i + 1;
                const label = `${period}${periodSuffix(Number(period))}`;
                return {
                    label: label,
                    value: period,
                };
            }),
        [totalPeriods]
    );

    // Renders
    const leftToolbar = (
        <div className="p-button-group">
            <>
                <Dropdown
                    onChange={(e) => setSeason(e.value)}
                    value={season}
                    options={seasonOptions}
                />
                <Dropdown
                    onChange={(e) => setEvent(e.value)}
                    value={event}
                    options={eventOptions}
                    itemTemplate={(o) => {
                        return (
                            <div
                                style={{
                                    opacity: o.error ? 0.5 : 1,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                }}
                            >
                                {o.label} {o.error && <Icon name="error" />}
                            </div>
                        );
                    }}
                />
                {categoryOptions.length > 2 && (
                    <Dropdown
                        onChange={(e) => setCategory(e.value)}
                        value={category}
                        options={categoryOptions}
                    />
                )}
                <div className="p-buttonset">
                    <SelectButton
                        value={filter}
                        multiple={false}
                        allowEmpty={false}
                        onChange={(e) => setFilter(e.value)}
                        options={[{ label: 'All', value: 0 }, ...periodOptions]}
                        disabled={event === ''}
                    />
                </div>
            </>
        </div>
    );

    const rightToolbar = (
        <div className="p-button-group">
            <div
                className="p-button p-button-text p-button-plain"
                style={{
                    display: 'inline-flex',
                    alignItems: 'center',
                    gap: '5px',
                    border: '1px solid #ced4da',
                    padding: '8px 15px',
                    borderRadius: '2px',
                    fontSize: '14px',
                    fontWeight: 'bold',
                }}
            >
                <label
                    style={{
                        margin: '0',
                        fontSize: '16px',
                        fontWeight: 'bold',
                        color: '#4f4f4f',
                    }}
                >
                    Hide Empty Cols
                </label>
                <InputSwitch
                    checked={hideEmptyColumns}
                    onChange={(e) => setHideEmptyColumns(e.value)}
                />
            </div>
            <SelectButton
                value={dataType}
                onChange={(e) => setDataType(e.value)}
                options={dataTypeOptions}
                disabled={event !== ''}
            />
            <RookieButton
                type="button"
                onClick={() => exportCSV()}
                label="Export CSV"
                severity="secondary"
            />
        </div>
    );

    const renderHeaderCell = (stat: StatConfig) => {
        const statDesc = config[stat.statID]?.description;

        return (
            <>
                <Tooltip
                    target={`.cell-${stat.statID}`}
                    autoHide={false}
                    position="right"
                    onBeforeHide={() => setShowInfo(false)}
                >
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div style={{ color: '#fff' }}>
                            <div>{stat.statName}</div>
                            {statDesc && showInfo && <div>{statDesc}</div>}
                        </div>
                        {statDesc && (
                            <RookieButton
                                onClick={() => setShowInfo(!showInfo)}
                                text={true}
                                icon={showInfo ? 'close' : 'info'}
                            />
                        )}
                    </div>
                </Tooltip>
                {stat.statShortName}
            </>
        );
    };

    const calculatedData = useMemo(() => {
        return dataType === ReportDataTypes.Average
            ? getAverages(filteredData)
            : getTotals(filteredData);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filteredData, players, dataType, getAverages, getTotals]);

    const calculatePlayedCount = (row: TeamStat) => {
        let value = null;
        if (reportData && reportData.data) {
            value = reportData.data.filter(
                (o: TeamStat) => o.playerID === row.playerID && o.period === 1
            ).length;
        }

        return value;
    };

    const tableColumns: StatConfig[] = useMemo(() => {
        const filteredColumns = statConfig?.filter((o) => {
            const emptyColumn = calculatedData.filter((item) => {
                return item[o.statID] && item[o.statID] > 0;
            });

            if (hideEmptyColumns && emptyColumn.length <= 0) {
                return null;
            }

            // Include periodPlusMinus explicitly
            const isPeriodPlusMinusIncluded = o.statID === 'periodPlusMinus';

            // Check if the current statID is included in the columns for the report type or if it's the periodPlusMinus
            return (
                columns[reportType as keyof typeof columns].includes(
                    o.statID
                ) || isPeriodPlusMinusIncluded
            );
        });

        const sortedColumns = sortBy(filteredColumns, 'statSortOrder');

        return sortedColumns;
    }, [calculatedData, hideEmptyColumns, reportType, statConfig]);

    const renderEmpty = () => {
        const msg = event
            ? "Can't load report for this event. The data may be corrupt. Contact us if you would like further information."
            : 'Unable to find report. Please try again later';
        const errorType = event
            ? ERROR_TYPES.somethingsWrong
            : ERROR_TYPES.notFound;
        const actions = event ? [{ label: 'Contact Us' }] : undefined;

        return (
            <ErrorDisplay
                alignment="middle"
                title="No Report Found"
                desc={msg}
                errorType={errorType}
                hasReturn={false}
                actions={actions}
                proportion="compact"
            />
        );
    };
    const tableFooter = (
        <div className="table-disclaimer">
            <span>Report generated by</span>{' '}
            <Image height="24px" src={playLogo} alt="Rookie Me Play" />
        </div>
    );

    return (
        <PageContainer>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <PageHeader title="Player Stat Report" />
                <RookieButton
                    style={{
                        backgroundColor: 'transparent',
                        border: 'none',
                        color: 'inherit',
                        opacity: 'inherit',
                        marginLeft: '-10px', // Use a negative margin to reduce space
                        marginTop: '50px',
                    }}
                    text={true}
                    data-beacon-article="67245ca620405a3f28d40735"
                    icon="help"
                />
            </div>
            <Toolbar start={leftToolbar} end={rightToolbar} />

            <DataTable
                ref={dataTable}
                removableSort
                showGridlines
                value={
                    reportData.isLoading || reportData.isUninitialized
                        ? Array(5)
                        : calculatedData
                }
                emptyMessage={renderEmpty()}
                scrollable
                scrollHeight="100vh"
                columnResizeMode="expand"
                resizableColumns
                footer={tableFooter}
                exportFilename={`season-${reportType}-player-report`}
                stripedRows={true}
            >
                <Column
                    frozen
                    align="center"
                    alignHeader="center"
                    header="#"
                    field="player.uniformNumber"
                    sortable
                    headerStyle={{ backgroundColor: 'white' }}
                />
                <Column
                    header="Player"
                    frozen
                    field="player.lastName"
                    body={playerNameCell}
                    sortable
                    exportable={false}
                    headerStyle={{ backgroundColor: 'white' }}
                />
                <Column header="First Name" field="player.firstName" hidden />
                <Column header="Last Name" field="player.lastName" hidden />
                <Column
                    header="Group"
                    field="player.colour"
                    body={playerGroupCell}
                    sortable
                    headerStyle={{ backgroundColor: 'white' }}
                />
                {event === '' && (
                    <Column
                        align="center"
                        alignHeader="center"
                        sortable
                        body={(row) =>
                            !row || reportData.isLoading ? (
                                <Skeleton />
                            ) : (
                                calculatePlayedCount(row)
                            )
                        }
                        header="Games"
                        headerStyle={{ backgroundColor: 'white' }}
                    />
                )}
                {team?.entitySportID === 'bball_au' && (
                    <Column
                        align="center"
                        alignHeader="center"
                        header={
                            <>
                                <Tooltip
                                    target=".cell-scoringTotalPoints"
                                    autoHide={false}
                                    position="right"
                                    onBeforeHide={() => setShowInfo(false)}
                                >
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <div style={{ color: '#fff' }}>
                                            <div>Points Total</div>
                                            {showInfo && (
                                                <div>
                                                    Total points for the given
                                                    filters applied.
                                                </div>
                                            )}
                                        </div>
                                        <RookieButton
                                            onClick={() =>
                                                setShowInfo(!showInfo)
                                            }
                                            icon={showInfo ? 'close' : 'info'}
                                        />
                                    </div>
                                </Tooltip>
                                PTS
                            </>
                        }
                        field="bball_total_points"
                        key="bball_total_points"
                        headerClassName="cell-scoringTotalPoints"
                        sortable
                        body={(row) =>
                            !row || reportData.isLoading ? (
                                <Skeleton width="2rem" height="1rem" />
                            ) : row.bball_scoring_ftg > 0 ||
                              row.bball_scoring_fg > 0 ||
                              row['bball_scoring_3-pg'] > 0 ? (
                                row.bball_scoring_ftg +
                                row.bball_scoring_fg * 2 +
                                row['bball_scoring_3-pg'] * 3
                            ) : (
                                '-'
                            )
                        }
                        headerStyle={{ backgroundColor: 'white' }}
                    />
                )}
                {tableColumns.map((stat, _, array) => {
                    if (stat.statName === 'Rushed') {
                        return null; // Hide rushed behinds
                    }
                    if (
                        stat.statCategory.name !== category &&
                        category !== ''
                    ) {
                        return null;
                    }

                    let columns = [
                        <Column
                            header={stat.statName}
                            field={stat.statID}
                            hidden
                        />,
                        <Column
                            field={stat.statID}
                            key={stat.statID}
                            align="center"
                            alignHeader="center"
                            header={renderHeaderCell(stat)}
                            headerStyle={{ backgroundColor: 'white' }}
                            headerClassName={`cell-${stat.statID}`}
                            sortable
                            body={(row) => {
                                return !row || reportData.isLoading ? (
                                    <Skeleton width="2rem" height="1rem" />
                                ) : row[stat.statID] ? (
                                    parseFloat(row[stat.statID].toFixed(1))
                                ) : (
                                    '-'
                                );
                            }}
                            exportable={false}
                        />,
                    ];

                    // Insert Scoring Accuracy columns
                    if (
                        team?.entitySportID === 'aus_football_au' &&
                        stat.statName === 'Missed Shot'
                    ) {
                        const scoringAccuracyColumn = getScoringAccuracyColumn(
                            team,
                            stat,
                            array,
                            reportData,
                            showInfo,
                            setShowInfo
                        );
                        if (scoringAccuracyColumn) {
                            columns.push(scoringAccuracyColumn);
                        }
                    } else if (
                        team?.entitySportID === 'bball_au' &&
                        stat.statName === 'Free Throw Miss'
                    ) {
                        const scoringAccuracyColumn = getScoringAccuracyColumn(
                            team,
                            stat,
                            array,
                            reportData,
                            showInfo,
                            setShowInfo
                        );
                        if (scoringAccuracyColumn) {
                            columns.push(scoringAccuracyColumn);
                        }
                    } else if (
                        team?.entitySportID === 'bball_au' &&
                        stat.statName === 'Field Goal Miss'
                    ) {
                        const scoringAccuracyColumn = getScoringAccuracyColumn(
                            team,
                            stat,
                            array,
                            reportData,
                            showInfo,
                            setShowInfo
                        );
                        if (scoringAccuracyColumn) {
                            columns.push(scoringAccuracyColumn);
                        }
                    } else if (
                        team?.entitySportID === 'bball_au' &&
                        stat.statName === '3-Point Miss'
                    ) {
                        const scoringAccuracyColumn = getScoringAccuracyColumn(
                            team,
                            stat,
                            array,
                            reportData,
                            showInfo,
                            setShowInfo
                        );
                        if (scoringAccuracyColumn) {
                            columns.push(scoringAccuracyColumn);
                        }
                    }

                    return columns;
                })}
            </DataTable>
        </PageContainer>
    );
};

export default PlayerStatsReport;
