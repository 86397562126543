import { isObject, isString } from 'lodash';
import { format } from 'date-fns';
import { useParams } from 'react-router-dom';
import { useGetPlayerQuery } from '../../api/players';

import ErrorDisplay from '../../components/ErrorDisplay';

import { ProgressSpinner } from 'primereact/progressspinner';

import { Player, PlayerStatus } from '../../types/team';
import { BaseEntityType, ERROR_TYPES } from '../../types/common';
import List from '../../components/List';
import ListItem from '../../components/ListItem';
import Icon from '../../components/Icon';
import EntityAvatar from '../../components/EntityAvatar';
import { Tag } from 'primereact/tag';

import { ReactComponent as AvatarPlaceholder } from '../../assets/images/avatar-placeholder.svg';
import ListHeader from '../../components/ListHeader';
import ListDivider from '../../components/ListDivider';
import { Badge } from 'primereact/badge';

interface Props {
    player?: Player | string;
    onDelete?: () => void;
    onEdit?: () => void;
}

const PlayerPane = (props: Props) => {
    const { teamID } = useParams();

    const { data, isLoading, isError, isFetching } = useGetPlayerQuery(
        {
            teamID: teamID || '',
            // @ts-expect-error
            playerID: props.player,
        },
        { skip: !props.player || !isString(props.player) || !teamID }
    );

    const player: Player | undefined = isObject(props.player)
        ? props.player
        : data?.data;

    if (isFetching) {
        return <ProgressSpinner id="isFetching" className="loading-spinner" />;
    }

    if (isLoading) {
        return <ProgressSpinner id="isLoading" className="loading-spinner" />;
    }

    if (isError) {
        return (
            <ErrorDisplay
                title="An error has occurred"
                errorType={ERROR_TYPES.somethingsWrong}
            />
        );
    }

    if (!player) {
        return (
            <ErrorDisplay
                title="No data returned"
                errorType={ERROR_TYPES.notFound}
            />
        );
    }
    return (
        <>
            <div className="profile-header">
                <div className="profile-avatar p-overlay-badge">
                    {player.playerImageURL ? (
                        <img
                            src={player.playerImageURL}
                            alt=""
                            style={{
                                borderRadius: '50%',
                                width: '100px',
                                height: '100px',
                                objectFit: 'cover',
                            }}
                        />
                    ) : (
                        <AvatarPlaceholder />
                    )}
                    <Badge
                        value={player.uniformNumber}
                        style={{ background: player.colour }}
                        size="large"
                    />
                </div>
                <h3 className="profile-title">
                    {player.firstName + ' ' + player.lastName}
                </h3>
                <p className="profile-caption">{player.displayName}</p>

                <Tag
                    severity={
                        player.status === PlayerStatus.Active
                            ? 'success'
                            : player.status === PlayerStatus.Archived
                            ? 'warning'
                            : 'danger'
                    }
                    value={player.status}
                />
            </div>
            <List>
                <ListDivider />
                <ListHeader>Player</ListHeader>
                <ListItem
                    start={
                        <span style={{ width: '24px', height: '24px' }}>
                            <EntityAvatar
                                entityName={player.teamName}
                                entityType={BaseEntityType.teams}
                                entityID={player.teamID}
                            />
                        </span>
                    }
                    overline="Team"
                    title={player.teamName}
                />
                <ListItem
                    start={<Icon name="apparel" />}
                    overline="Playing Number"
                    title={player.uniformNumber}
                />
                <ListDivider />
                <ListHeader>Personal</ListHeader>
                <ListItem
                    start={<Icon name="person" />}
                    overline="Gender"
                    title={player.gender}
                />
                {player.dateOfBirth && (
                    <ListItem
                        start={<Icon name="cake" />}
                        overline="Date of birth"
                        title={format(
                            new Date(player.dateOfBirth),
                            'MMMM dd, yyyy'
                        )}
                    />
                )}
                <ListDivider />
                <ListHeader>Activity</ListHeader>
                <ListItem
                    start={<Icon name="calendar_add_on" />}
                    overline="Created"
                    title={format(new Date(player.createdAt), 'dd/MM/yyyy')}
                />
                <ListItem
                    start={<Icon name="edit_calendar" />}
                    overline="Last Edited"
                    title={format(new Date(player.lastEdited), 'dd/MM/yyyy')}
                />
            </List>
        </>
    );
};

export default PlayerPane;
