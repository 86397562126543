import React from 'react';
import { isArray } from 'lodash';
import BreadCrumbs from '../components/BreadCrumbs';
import RookieButton from '../components/RookieButton';

import { Action } from '../types/common';

interface Props {
    title: string;
    description?: string;
    actions?: Action[];
    showBreadcrumbs?: boolean;
    beaconArticle?: string;
}

const PageHeader = (props: Props) => {
    const { actions, description, title, beaconArticle } = props;

    /**
     * @desc returns page heading content
     * @returns JSX
     */
    const renderHeading = () => {
        return (
            <div
                className={`page-header_middle--left ${
                    description ? 'has-desc' : ''
                }`}
            >
                <div className="page-header_title title">
                    <h1>{title}</h1>
                    {beaconArticle && (
                        <RookieButton
                            className="page-header_beacon"
                            text={true}
                            data-beacon-article={beaconArticle}
                            icon="help"
                        />
                    )}
                </div>
                {description && (
                    <p className="page-header_desc">{description}</p>
                )}
            </div>
        );
    };

    /**
     * @desc options and actions props passed in, mapped over and rendered through primereact components.
     * @returns JSX
     */
    const renderActions = () => {
        if (!actions) return null;
        return (
            <div className="page-header_middle--right">
                <div className="p-button-group">
                    {isArray(actions)
                        ? actions.map((action, i) => {
                              // If a component is provided, render it directly
                              if (action.component) {
                                  return (
                                      <React.Fragment
                                          key={
                                              action.key ||
                                              `page-header-action-${i}`
                                          }
                                      >
                                          {action.component}
                                      </React.Fragment>
                                  );
                              }

                              return (
                                  <RookieButton
                                      key={
                                          action.key ||
                                          `page-header-action-${i}`
                                      }
                                      label={action.label}
                                      severity={action.severity}
                                      className={action.className}
                                      icon={action.icon || ''}
                                      onClick={() => {
                                          action.command && action.command();
                                      }}
                                  />
                              );
                          })
                        : actions}
                </div>
            </div>
        );
    };

    return (
        <div className="page-header">
            {props.showBreadcrumbs && (
                <div className="page-header_top">
                    <BreadCrumbs />
                </div>
            )}
            <div className="page-header_middle">
                {renderHeading()}
                {actions && renderActions()}
            </div>
        </div>
    );
};

PageHeader.defaultProps = {
    showBreadcrumbs: true,
};

export default PageHeader;
