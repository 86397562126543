import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Formik, FormikHelpers } from 'formik';
import { orderBy, pick } from 'lodash';

import { useLazyGetRolesQuery } from '../../api/core';
import {
    useDeleteOrganisationMutation,
    useGetOrganisationQuery,
    useUpsertOrganisationMutation,
} from '../../api/organisations';
import {
    useGetEntityCategoriesQuery,
    useGetSportsQuery,
} from '../../api/sports';

import { Mixpanel } from '../../util/mixpanel';

import { Avatar } from 'primereact/avatar';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { ProgressBar } from 'primereact/progressbar';
import { Skeleton } from 'primereact/skeleton';
import { Tooltip } from 'primereact/tooltip';

import EntityDesigner from '../../components/EntityDesigner';
import EntityPreview from '../../components/EntityPreview';
import Icon from '../../components/Icon';
import ListItem from '../../components/ListItem';
import RookieButton from '../../components/RookieButton';
import FormActions from '../../components/FormActions';
import FormFields from '../../components/FormFields';
import FormGroup from '../../components/FormGroup';

import { INPUT_TYPES } from '../../types/common';
import { OrganisationFormData } from '../../types/organisation';
import { Design } from '../../types/design';
import { Sport } from '../../types/sports';
import { Dropdown } from 'primereact/dropdown';

interface FormProps {
    design: Design;
    organisationName: string;
    entityCategoryID?: string;
    shortName?: string;
    sportID?: string;
}

interface OrganisationFormProps {
    formHasActions: boolean;
    initialValues: FormProps;
    organisationID?: OrganisationFormData['organisationID'];
    onCancel?: () => void;
    onSuccess?: (data: any) => void;
    onError?: (error: any) => void;
    onDelete?: (status: string) => void;
    onUpdate?: (data: any) => void;
}

export default function OrganisationForm(
    props: OrganisationFormProps
): JSX.Element {
    const {
        formHasActions,
        initialValues,
        organisationID: organisationIDProp,
        onDelete,
        onSuccess,
        onError,
        onUpdate,
    } = props;
    const { organisationID: organisationIDParam, userID } = useParams();

    const organisationID = organisationIDProp || organisationIDParam;

    const [selectedSport, setSelectedSport] = useState<string | undefined>();

    const [showEditor, setShowEditor] = useState(false);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);

    const [getRoles] = useLazyGetRolesQuery();
    const [upsertOrganisation] = useUpsertOrganisationMutation();
    const [deleteOrganisation] = useDeleteOrganisationMutation();

    const { data: organisationData } = useGetOrganisationQuery(
        { organisationID },
        { skip: !organisationID }
    );

    const { data: sports, isLoading: isLoadingSports } = useGetSportsQuery();

    const { data: categories, isLoading: isLoadingCategories } =
        useGetEntityCategoriesQuery(
            { sportID: selectedSport || '' },
            { skip: !selectedSport }
        );

    useEffect(() => {
        if (organisationData?.data) {
            setSelectedSport(organisationData.data.entitySportID);
        }
    }, [organisationData]);

    const requiredFields: Array<keyof FormProps> = [
        'design',
        'sportID',
        'organisationName',
        'shortName',
        'entityCategoryID',
    ];

    const isCreate = !organisationID;

    const handleSubmit = (
        data: FormProps,
        { setSubmitting }: FormikHelpers<FormProps>
    ) => {
        setSubmitting(true);

        const args: any = {};

        if (isCreate) {
            args.entitySportID = selectedSport;
        } else {
            delete data.entityCategoryID;
        }

        upsertOrganisation({
            organisationID,
            ...args,
            ...data,
        })
            .then((response) => {
                if ('error' in response) {
                    if (onError) {
                        onError('An error has occured');
                    }
                } else {
                    organisationID
                        ? onUpdate && onUpdate(response)
                        : onSuccess && onSuccess(response);

                    Mixpanel.track(
                        `${organisationID ? 'Update' : 'Create'} Organisation`
                    );
                }
            })
            .catch((error) => {
                if (onError) onError(error);
            })
            .finally(() => {
                setSubmitting(false);
            });
    };

    const handleDelete = ({
        setSubmitting,
    }: {
        setSubmitting: (isSubmitting: boolean) => void;
    }) => {
        // set formik to submitting
        setSubmitting(true);

        // close modal to show form
        setShowDeleteDialog(false);

        // init deletion method
        deleteOrganisation({ organisationID: organisationID ?? '' })
            .then(() => {
                onDelete && onDelete('success');
            })
            .catch((err) => {
                console.dir(err);
                onDelete && onDelete('error');

                if (userID) {
                    getRoles({ userID });
                }
            })
            .finally(() => {
                setSubmitting(false);
            });
    };

    const confirmRemovalModalHeader = (
        <h4 className={'title-is-bold'}>Confirm Deletion</h4>
    );

    const confirmRemovalModalHeaderIcon = (
        <RookieButton
            className="ui-button"
            severity="secondary"
            text={true}
            onClick={() => setShowDeleteDialog(false)}
            type="button"
            icon="close"
        />
    );

    const confirmRemovalModalContent = !isCreate && (
        <div className="modal-content">
            <p className="delete-msg">
                Are you sure you want to delete this Organisation?
            </p>
            <div className="list">
                <ListItem
                    start={<Avatar label={initialValues.organisationName} />}
                    title={initialValues.organisationName}
                />
            </div>
        </div>
    );

    const sportOptions = useMemo(() => {
        const options = sports?.data.map((sport: Sport) => ({
            label: sport.sportName,
            value: sport.sportID,
        }));

        return orderBy(options, 'label');
    }, [sports]);

    const entityCategories = useMemo(() => {
        const options = categories?.data
            ?.filter((cat) => cat.entityType === 'organisations')
            .map((cat) => ({
                label: cat.entityCategoryName,
                value: cat.entityCategoryID,
                name: cat.entityCategoryName,
            }));

        return orderBy(options, 'label');
    }, [categories]);

    return (
        <Formik
            initialValues={pick(initialValues, requiredFields)}
            validate={(values) => {
                const errors: { [key: string]: string } = {};
                requiredFields.forEach((field) => {
                    if (!values[field]) {
                        errors[field] = 'Required';
                    }
                });
                return errors;
            }}
            validateOnBlur={false}
            validateOnChange={false}
            onSubmit={handleSubmit}
        >
            {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                setFieldValue,
                setFieldTouched,
                setSubmitting,
            }) => (
                <>
                    <Dialog
                        header="Kit Designer"
                        onHide={() => setShowEditor(false)}
                        visible={showEditor}
                    >
                        <EntityDesigner
                            initialValues={values.design}
                            onCancel={() => setShowEditor(false)}
                            onSubmit={(value) => {
                                setFieldValue('design', value);
                                setShowEditor(false);
                            }}
                            submitLabel="Save Design"
                        />
                    </Dialog>

                    <form onSubmit={handleSubmit}>
                        <FormFields>
                            <FormGroup className="kit-edit_wrap">
                                <div
                                    className="kit-edit"
                                    onClick={() => setShowEditor(true)}
                                >
                                    <EntityPreview design={values.design} />
                                    <div className="edit-badge">
                                        <Icon name="edit" size="small"></Icon>
                                    </div>
                                </div>
                            </FormGroup>

                            {!organisationID && (
                                <FormGroup label="Sport" htmlFor="sportID">
                                    {isLoadingSports ? (
                                        <Skeleton height="44px" />
                                    ) : (
                                        <Dropdown
                                            value={selectedSport}
                                            options={sportOptions}
                                            onChange={(e) => {
                                                setSelectedSport(e.value);
                                                setFieldValue(
                                                    'entityCategoryID',
                                                    ''
                                                );
                                            }}
                                            id="sportID"
                                            name="sportID"
                                            required
                                        />
                                    )}
                                </FormGroup>
                            )}
                            {selectedSport && (
                                <FormGroup
                                    label="Organisation Type"
                                    htmlFor="entityCategoryID"
                                    error={errors.entityCategoryID}
                                    showError={
                                        !!errors.entityCategoryID &&
                                        touched.entityCategoryID
                                    }
                                >
                                    {isLoadingCategories ? (
                                        <Skeleton height="44px" />
                                    ) : (
                                        <Dropdown
                                            id="entityCategoryID"
                                            name="entityCategoryID"
                                            value={values.entityCategoryID}
                                            options={entityCategories}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            disabled={!isCreate}
                                        />
                                    )}
                                </FormGroup>
                            )}
                            <FormGroup
                                label="Organisation Name"
                                htmlFor="organisationName"
                                error={errors.organisationName}
                                showError={
                                    !!errors.organisationName &&
                                    touched.organisationName
                                }
                            >
                                <InputText
                                    id="organisationName"
                                    name="organisationName"
                                    type={INPUT_TYPES.text}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    placeholder="Enter Organisation Name"
                                    value={values.organisationName}
                                    required={isCreate}
                                />
                            </FormGroup>
                            <FormGroup
                                label={
                                    <>
                                        Short Name
                                        <Tooltip target=".shortName-help" />
                                        <span
                                            className="help-tip shortName-help"
                                            data-pr-tooltip="Short Name can contain only alphabetical characters."
                                            data-pr-position="top"
                                        >
                                            <Icon name="info" size="small" />
                                        </span>
                                        {values.shortName && (
                                            <small className="form-label--right">
                                                {values.shortName.length}
                                                /4
                                            </small>
                                        )}
                                    </>
                                }
                                htmlFor="shortName"
                                error={errors.shortName}
                                showError={
                                    !!errors.shortName && touched.shortName
                                }
                            >
                                <InputText
                                    id="shortName"
                                    maxLength={4}
                                    name="shortName"
                                    onBlur={handleBlur}
                                    onChange={(event) => {
                                        let shortNameVal = event.target.value;
                                        shortNameVal = shortNameVal.replace(
                                            /[^a-zA-Z]/g,
                                            ''
                                        );

                                        shortNameVal =
                                            shortNameVal.toUpperCase();

                                        setFieldValue(
                                            'shortName',
                                            shortNameVal
                                        );

                                        setFieldTouched('shortName', true);
                                    }}
                                    placeholder="Short Name"
                                    required
                                    type={INPUT_TYPES.text}
                                    value={values.shortName}
                                />
                            </FormGroup>
                        </FormFields>

                        {formHasActions && (
                            <FormActions
                                start={
                                    <RookieButton
                                        disabled={isSubmitting}
                                        type="submit"
                                        className={
                                            isSubmitting
                                                ? 'is-submitting'
                                                : undefined
                                        }
                                        label={
                                            !isCreate && isSubmitting
                                                ? 'Updating'
                                                : !isCreate
                                                ? 'Save'
                                                : isSubmitting
                                                ? 'Creating'
                                                : 'Create Organisation'
                                        }
                                    />
                                }
                            />
                        )}

                        {isSubmitting && (
                            <div className="form-overlay">
                                <ProgressBar mode="indeterminate" />
                            </div>
                        )}
                        {!isCreate && (
                            <ConfirmDialog
                                className="modal delete-modal"
                                closeOnEscape
                                draggable={false}
                                dismissableMask
                                footer={
                                    <>
                                        <RookieButton
                                            text={true}
                                            severity="secondary"
                                            icon="cancel"
                                            label="Cancel"
                                            onClick={() =>
                                                setShowDeleteDialog(false)
                                            }
                                            type="button"
                                        />
                                        <RookieButton
                                            severity="danger"
                                            onClick={() =>
                                                handleDelete({ setSubmitting })
                                            }
                                            type="submit"
                                            icon="delete"
                                            label="Yes, Delete"
                                        />
                                    </>
                                }
                                header={confirmRemovalModalHeader}
                                icons={confirmRemovalModalHeaderIcon}
                                message={confirmRemovalModalContent}
                                onHide={() => setShowDeleteDialog(false)}
                                visible={showDeleteDialog}
                            />
                        )}
                    </form>
                </>
            )}
        </Formik>
    );
}

OrganisationForm.defaultProps = {
    formHasActions: true,
    initialValues: {
        organisationName: '',
        shortName: '',
        entityCategoryID: '',
        design: {
            template: '',
            primary: '',
            secondary: '',
            tertiary: '',
        },
    },
};
