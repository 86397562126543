import { useEffect, useMemo, useState, useRef, useContext } from 'react';
import {
    generatePath,
    useNavigate,
    useParams,
    useSearchParams,
} from 'react-router-dom';
import { isEmpty, omitBy } from 'lodash';

import { useGetEventsQuery, useDeleteEventMutation } from '../../api/events';

import { getRouteByID } from '../../routes/routes';
import { ToastContext } from '../../contexts/ToastContext';
import { ToastMessage } from 'primereact/toast';
import { getEntityFromParam, toISOStringWithTimezone } from '../../util/helper';

import {
    ConfirmDialog,
    confirmDialog,
    ConfirmDialogReturn,
} from 'primereact/confirmdialog';
import Icon from '../../components/Icon';
import EventsView from './EventsView';

import { Roles } from '../../types/roles';
import { Route } from '../../types/route';
import { Event, EventParams } from '../../types/event';
import { Permissions } from '../../types/permissions';
import usePermission from '../../hooks/usePermission';
import { ReportStatus } from '../../types/reports';
import { Mixpanel } from '../../util/mixpanel';
import { useGetTeamOwnedSeasonsQuery } from '../../api/seasons';
import { BaseEntityType } from '../../types/common';

interface Props {
    roles: Roles;
    route: Route;
}

const POLL_INTERVAL = 30000;

const EventsContainer = (props: Props) => {
    const params = useParams();
    const { teamID, organisationID, associationID } = params;
    const toast = useContext(ToastContext);
    const activeEntity = useMemo(() => getEntityFromParam(params), [params]);

    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const tab = searchParams.get('tab');

    // Permission Hook
    const { checkPermission } = usePermission(organisationID || teamID || '');
    const showToast = (toastOptions: ToastMessage) => {
        if (toast && toast.current) {
            toast.current.show(toastOptions);
        }
    };
    // State Hooks
    const [showEventDialog, setShowEventDialog] = useState<boolean>(false);
    const [selectedEvent, setSelectedEvent] = useState<Event | null>(null);
    const [shouldPoll, setShouldPoll] = useState<boolean>(false);
    const [filters, setFilters] = useState<EventParams>({
        cursor: '',
    });

    // API Hooks
    const [deleteEventMutation] = useDeleteEventMutation();

    // Get all filters
    const allFilters = useMemo(() => {
        const today = new Date();
        today.setHours(0, 0, 0, 0);

        return omitBy(
            {
                ...filters,
                order: tab === 'past' || tab === 'archived' ? 'desc' : 'asc',
                to:
                    tab === 'past' || tab === 'archived'
                        ? toISOStringWithTimezone(today)
                        : '',
                expand: 'gameSummaryReport',
                //eventType: EventType.Game,
                status: tab === 'archived' ? 'Archived' : null,
            },
            isEmpty
        );
    }, [filters, tab]);

    // Query events
    const { data, isError, isFetching, isLoading } = useGetEventsQuery(
        {
            // @ts-expect-error entityType param may not exist
            entityType: activeEntity?.entityType,
            // @ts-expect-error entityID param may not exist
            entityID: activeEntity?.entityID,
            ...allFilters,
        },
        {
            skip: !activeEntity,
            pollingInterval: shouldPoll ? POLL_INTERVAL : undefined,
        }
    );

    const { data: seasonsRawData } = useGetTeamOwnedSeasonsQuery(
        {
            teamID: activeEntity?.entityID || '',
            cursor: '',
        },
        {
            skip: activeEntity?.entityType !== BaseEntityType.teams,
        }
    );

    const seasons = seasonsRawData?.data || [];

    useEffect(() => {
        const shouldPoll = !!data?.data.some(
            (arr: Event) =>
                arr?.gameDetails?.gameSummaryReport &&
                [
                    ReportStatus.Queued,
                    ReportStatus.Started,
                    ReportStatus.Building,
                ].includes(arr.gameDetails.gameSummaryReport.reportStatus)
        );

        setShouldPoll(shouldPoll);
    }, [data]);
    const deleteEvent = (eventID: string) => {
        if (activeEntity) {
            deleteEventMutation({
                entityType: activeEntity.entityType,
                entityID: activeEntity.entityID,
                eventID,
            })
                .then((response) => {
                    // Close the dialog after successful deletion
                    if (dialogRef.current) {
                        dialogRef.current.hide();
                    }
                    // Show success toast message
                    showToast({
                        severity: 'success',
                        detail: 'Successfully deleted event.',
                    });
                })
                .catch((err) => {
                    // Optionally close the dialog even in case of an error
                    if (dialogRef.current) {
                        dialogRef.current.hide();
                    }
                    // Show error toast message
                    showToast({
                        severity: 'error',
                        detail: 'There was an error deleting the event. Please try again.',
                        summary: 'Error',
                    });
                });
        }
    };

    const handleSelectEvent = (value: Event | null) => {
        setSelectedEvent(value);
    };

    const handleLoadMore = () => {
        if (data?.lastEvaluatedKey) {
            const { lastEvaluatedKey } = data;
            if (lastEvaluatedKey && lastEvaluatedKey.cursor) {
                setFilters({
                    ...filters,
                    cursor: lastEvaluatedKey.cursor,
                });
            }
        }
    };

    const dialogRef = useRef<ConfirmDialogReturn | null>(null);

    const handleDeleteEvent = (event: Event) => {
        // Store the dialog instance in the ref
        dialogRef.current = confirmDialog({
            message: `Are you sure you want to delete ${event.eventName}?`,
            header: 'Delete Event',
            icon: <Icon name="dangerous" />,
            accept: () => {
                // Call the delete function and then close the dialog
                if (dialogRef.current) {
                    dialogRef.current.hide();
                }
                deleteEvent(event.rootID);
            },
        });
    };

    const handleEditEvent = (event: Event) => {
        setSelectedEvent(event);
        setShowEventDialog(true);

        Mixpanel.track('Open Event Edit');
    };

    const handleCreateEvent = () => {
        setSelectedEvent(null);
        setShowEventDialog(true);

        Mixpanel.track('Open Event Create');
    };

    const handleClickEvent = (event: Event) => {
        if (activeEntity) {
            const url =
                organisationID && teamID
                    ? getRouteByID(`organisations-teams-event`)
                    : getRouteByID(`${activeEntity.entityType}-event`);

            if (url) {
                navigate(
                    generatePath(url.path, {
                        teamID,
                        organisationID,
                        associationID,
                        eventID: event.rootID,
                    })
                );
            }
        }
    };

    const handleCloseEventDialog = () => {
        setShowEventDialog(false);
    };

    useEffect(() => {
        // Handle redirection to "upcoming" for specific URLs with /events
        if (!tab || !['upcoming', 'past', 'archived'].includes(tab)) {
            navigate(
                {
                    search: '?tab=past',
                },
                {
                    replace: true,
                }
            );
        }
    }, [navigate, tab]);

    const permissions = {
        canCreate: checkPermission([
            Permissions.POST_TEAMS_EVENTS,
            Permissions.MANAGE_TEAMS_EVENTS,
            Permissions.MANAGE_TEAMS_ALL,
            Permissions.MANAGE_ORGANISATIONS_ALL,
            Permissions.MANAGE_ORGANISATIONS_TEAMS_ALL,
            Permissions.MANAGE_ASSOCIATIONS,
            Permissions.MANAGE_ASSOCIATIONS_ALL,
        ]),
        canDelete: checkPermission([
            Permissions.DELETE_TEAMS_EVENTS,
            Permissions.MANAGE_TEAMS_EVENTS,
            Permissions.MANAGE_TEAMS_ALL,
            Permissions.MANAGE_ORGANISATIONS_ALL,
            Permissions.MANAGE_ORGANISATIONS_TEAMS_ALL,
            Permissions.MANAGE_ASSOCIATIONS,
            Permissions.MANAGE_ASSOCIATIONS_ALL,
        ]),
        canEdit: checkPermission([
            Permissions.PUT_TEAMS_EVENTS,
            Permissions.MANAGE_TEAMS_EVENTS,
            Permissions.MANAGE_TEAMS_ALL,
            Permissions.MANAGE_ORGANISATIONS_ALL,
            Permissions.MANAGE_ORGANISATIONS_TEAMS_ALL,
            Permissions.MANAGE_ASSOCIATIONS,
            Permissions.MANAGE_ASSOCIATIONS_ALL,
        ]),
        canView: checkPermission([
            Permissions.GET_TEAMS_EVENTS,
            Permissions.MANAGE_TEAMS_EVENTS,
            Permissions.MANAGE_TEAMS_ALL,
            Permissions.MANAGE_ORGANISATIONS_ALL,
            Permissions.MANAGE_ORGANISATIONS_TEAMS_ALL,
            Permissions.MANAGE_ASSOCIATIONS,
            Permissions.MANAGE_ASSOCIATIONS_ALL,
        ]),
    };

    return (
        <>
            <EventsView
                data={data ? data.data : []}
                isError={isError}
                isFetching={isFetching}
                isLoading={isLoading}
                onClickEvent={handleClickEvent}
                onCloseEventDialog={handleCloseEventDialog}
                onCreateEvent={handleCreateEvent}
                onDeleteEvent={handleDeleteEvent}
                onEditEvent={handleEditEvent}
                onLoadMore={handleLoadMore}
                onSelectEvent={handleSelectEvent}
                onSetFilters={setFilters}
                roles={props.roles}
                route={props.route}
                filters={filters}
                selectedEvent={selectedEvent}
                showEventDialog={showEventDialog}
                showPagination={!!data?.lastEvaluatedKey?.cursor}
                permissions={permissions}
                seasons={seasons}
                orderBy={tab === 'past' || tab === 'archived' ? 'desc' : 'asc'}
            />
            <ConfirmDialog />
        </>
    );
};

export default EventsContainer;
