import React, { useRef, useState, useContext, useMemo } from 'react';
import { useSearchParams, useParams } from 'react-router-dom';
import { uniq } from 'lodash';

import { EVENTS_FIELD } from '../../util/importFields';

import { Dropdown } from 'primereact/dropdown';
import { Sidebar } from 'primereact/sidebar';
import { TabPanel, TabView } from 'primereact/tabview';
import { Toast } from 'primereact/toast';
import { SplitButton } from 'primereact/splitbutton';

import PageHeader from '../../layout/PageHeader';
import SpreadsheetImport from '../../components/SpreadsheetImport';

import EventForm from './EventForm';
import {
    EventFormData,
    EventStatus,
    EventType,
    LocationCategory,
} from '../../types/event';
import EventsListing from './EventListing';
import { ToastContext } from '../../contexts/ToastContext';
import { useUpsertEventMutation } from '../../api/events';
import useGooglePlaceAutoComplete from '../../hooks/googlePlaces';
import { convertToDate, toISOStringWithTimezone } from '../../util/helper';
import { useGetTeamQuery } from '../../api/teams';

import { Action, BaseEntityType } from '../../types/common';
import { Event, EventParams } from '../../types/event';

import { Roles } from '../../types/roles';
import { Route } from '../../types/route';
import PageContainer from '../../layout/PageContainer';
import { Season } from '../../types/seasons';

interface TabItem {
    id: string;
    title: string;
    component: any;
}

interface Props {
    data: Event[];
    isError: boolean;
    isFetching: boolean;
    isLoading: boolean;
    filters: EventParams;
    onClickEvent: (event: Event) => void;
    onCloseEventDialog: () => void;
    onCreateEvent: () => void;
    onDeleteEvent: (event: Event) => void;
    onEditEvent: (event: Event) => void;
    onLoadMore: () => void;
    onSelectEvent: (event: Event | null) => void;
    onSetFilters?: React.Dispatch<React.SetStateAction<EventParams>>;
    roles: Roles;
    route: Route;
    selectedEvent: Event | null;
    showEventDialog: boolean;
    showPagination: boolean;
    activeTab?: any;
    orderBy?: 'asc' | 'desc';
    seasons: Season[];
    permissions: {
        canCreate: boolean;
        canDelete: boolean;
        canEdit: boolean;
        canView: boolean;
    };
}

const CREATE_FORM_ID = 'create-event-form';

const EventsView = (props: Props) => {
    const { onCloseEventDialog, onCreateEvent, selectedEvent, permissions } =
        props;
    const { teamID } = useParams();
    const toast = useContext(ToastContext);
    const { search, getDetails, getFullAddress } = useGooglePlaceAutoComplete();
    const [showImportModal, setShowImportModal] = useState(false);
    const [selectedEventType, setSelectedEventType] = useState<string | null>(
        'ALL'
    );

    const eventSingleFormToast = useRef<Toast>(null);

    const teamData = useGetTeamQuery(
        { teamID: teamID || '' },
        {
            skip: !teamID,
        }
    );

    const [searchParams, setSearchParams] = useSearchParams();

    const handleEventSubmit = (isCreate: boolean) => {
        onCloseEventDialog();

        eventSingleFormToast.current?.show({
            severity: isCreate ? 'success' : 'success',
            summary: isCreate ? 'New event created' : 'Event changes saved',
            detail: isCreate
                ? 'Your event has been created and is ready to manage.'
                : 'This events details have been updated.',
        });
    };

    const pageHeaderActions: Action[] = useMemo(() => {
        const availableEventTypes = [
            { label: 'All Event Types', value: 'ALL' }, // Add this option first
            ...Object.keys(EventType).map((type) => ({
                label: type,
                value: type,
            })),
        ];

        const createActions: Action[] =
            teamData?.data?.data.teamStatus !== 'Archived' &&
            !permissions.canCreate
                ? []
                : [
                      {
                          key: 'import',
                          component: (
                              <SplitButton
                                  label="Import Events"
                                  onClick={() => setShowImportModal(true)}
                                  menuStyle={{ whiteSpace: 'nowrap' }}
                                  model={[
                                      {
                                          label: 'Download Template',
                                          icon: 'pi pi-download',
                                          command: () => {
                                              const templateUrl =
                                                  'https://cdn.home.rookieme.com/assets/hub/event_import_template.xlsx';

                                              window.open(
                                                  templateUrl,
                                                  '_blank'
                                              );
                                          },
                                      },
                                  ]}
                                  dropdownIcon="pi pi-chevron-down"
                                  className="p-button-secondary"
                              />
                          ),
                      },
                      {
                          key: 'create',
                          label: 'Add Event',
                          command: onCreateEvent,
                          icon: 'add',
                          type: 'button',
                      },
                  ];

        return [
            {
                key: 'eventTypeDropdown',
                component: (
                    <Dropdown
                        value={selectedEventType}
                        options={availableEventTypes}
                        onChange={(e) => {
                            setSelectedEventType(e.value);
                        }}
                    />
                ),
            },
            ...createActions,
        ];
    }, [permissions, selectedEventType, teamData, onCreateEvent]);

    const tabs: TabItem[] = [
        ...(teamData.data?.data.teamStatus !== 'Archived'
            ? [
                  {
                      id: 'past',
                      title: 'Past',
                      component: EventsListing,
                  },
                  {
                      id: 'upcoming',
                      title: 'Upcoming',
                      component: EventsListing,
                  },
              ]
            : []),
        {
            id: 'archived',
            title: 'Archived',
            component: EventsListing,
        },
    ];

    const activeTabIndex = tabs.findIndex(
        (tab) => searchParams.get('tab') === tab.id
    );

    const fetchGooglePlaceData = async (address: string) => {
        const searchResults = await search(address);
        if (Array.isArray(searchResults) && searchResults.length > 0) {
            const detailedInfo: any = await getDetails(
                searchResults[0].place_id
            );
            let addressDetails: any = null;
            try {
                addressDetails = await getFullAddress(detailedInfo);
            } catch (error) {
                console.warn('Error getting full address:', error);
            }

            // Check if addressDetails is defined before returning
            if (addressDetails) {
                return addressDetails;
            } else {
                return null;
            }
        }
        return null;
    };

    const [upsertEvent] = useUpsertEventMutation();

    const handleSubmit = async (spreadsheetData: any) => {
        const { validData, all } = spreadsheetData;
        let errors: string[] = [];

        // Set seasonID to '' where eventType is 'Other'
        validData.forEach((event: any) => {
            if (event.eventType === 'Other') {
                event.seasonID = '';
            }
        });

        // Show initial summary
        if (toast && toast.current) {
            toast.current.show({
                summary: `Importing ${all.length} events`,
                sticky: true,
            });
        }

        // Create events
        const results = await Promise.allSettled(
            validData.map(async (event: any) => {
                let formData: EventFormData = {
                    seasonID: event?.seasonID,
                    eventName: event.eventName,
                    eventType: event.eventType,
                    eventStatus: EventStatus.Published,
                    description: event.description || '',
                    game: {
                        isHomeTeam: event.isHomeTeam || false,
                        opponentTeam: {
                            teamName: event.opponentTeam || 'Unknown',
                        },
                    },
                };

                const startDateTime = convertToDate(
                    event.startDate,
                    event.startTime
                );

                formData.startDateTime = toISOStringWithTimezone(startDateTime);

                if (event.endDate && event.endTime) {
                    const endDateTime = convertToDate(
                        event.endDate,
                        event.endTime
                    );

                    formData.endDateTime = toISOStringWithTimezone(endDateTime);
                }

                return fetchGooglePlaceData(event.address)
                    .then((addressDetails) => {
                        if (addressDetails) {
                            formData.location = {
                                category: LocationCategory.Used,
                                country: addressDetails.countryLong,
                                state: addressDetails.locality,
                                locationName: addressDetails.name,
                                latitude: addressDetails.latitude,
                                longitude: addressDetails.longitude,
                                suburb: addressDetails.adminArea1Short,
                                city: addressDetails.adminArea1Long,
                                route: addressDetails.routeShort,
                                placeID: addressDetails.placeID,
                                postCode: Number(addressDetails.postalCode),
                                streetNumber: addressDetails.streetNumberShort,
                                formattedAddress:
                                    addressDetails.formattedAddress,
                            };
                            formData.location.locationExtraInfo =
                                event.locationExtraInfo;
                        }

                        if (teamID) {
                            return upsertEvent({
                                entityType: BaseEntityType.teams,
                                entityID: teamID,
                                eventID: formData.eventID,
                                data: formData,
                            });
                        }
                    })
                    .then((response) => {
                        return response && 'data' in response && response.data;
                    })
                    .catch((err) => {
                        errors.push(err.toString());
                        return false;
                    });
            })
        );

        const successCount = results.filter(
            (result): result is PromiseFulfilledResult<number> =>
                result.status === 'fulfilled' && result.value
        ).length;

        const uniqErrors = uniq(errors);
        const failedCount = all.length - successCount;

        // Show summary of import
        if (toast && toast.current) {
            toast.current.replace({
                severity:
                    successCount === 0
                        ? 'error'
                        : successCount === validData.length
                        ? 'success'
                        : undefined,
                summary: `${successCount}/${all.length} Imported`,
                detail:
                    failedCount > 0
                        ? `${failedCount} failed due to the following reasons: ${
                              uniqErrors.length > 0
                                  ? uniqErrors.toString()
                                  : validData.length !== all.length
                                  ? 'Invalid data'
                                  : 'Unknown'
                          }`
                        : '',
                sticky: false,
            });
        }
    };

    const importFields = [
        {
            label: 'Season',
            key: 'seasonID',
            example: '2024',
            fieldType: {
                type: 'select',
                options: props.seasons.map((season) => ({
                    label: season.seasonName,
                    value: season.seasonID,
                })),
            },
            validations: [
                {
                    rule: 'required',
                    errorMessage: 'Season is required',
                    level: 'error',
                },
            ],
            alternateMatches: [
                'season',
                'Season',
                'season name',
                'Season Name',
                'competition',
                'Competition',
            ],
        },
        ...EVENTS_FIELD,
    ];

    const filteredData =
        selectedEventType && selectedEventType !== 'ALL'
            ? props.data.filter((item) => item.eventType === selectedEventType)
            : props.data;

    return (
        <>
            <PageContainer>
                <PageHeader actions={pageHeaderActions} title="Events" />
                {props.isLoading ? (
                    <div>Loading...</div>
                ) : (
                    <TabView
                        activeIndex={activeTabIndex}
                        onTabChange={(e) => {
                            if (props.onSetFilters) {
                                props.onSetFilters((prev) => ({
                                    ...prev,
                                    cursor: '',
                                }));
                            }
                            setSearchParams({ tab: tabs[e.index].id });
                        }}
                    >
                        {tabs.map(({ id, title, component: TabContent }) => {
                            return (
                                <TabPanel key={title} header={title}>
                                    <TabContent
                                        activeTab={tabs[activeTabIndex]}
                                        orderBy={id === 'past' ? 'desc' : 'asc'}
                                        {...props}
                                        data={filteredData}
                                    />
                                </TabPanel>
                            );
                        })}
                    </TabView>
                )}
            </PageContainer>
            <Sidebar
                header={
                    selectedEvent && selectedEvent.eventID
                        ? 'Edit Event'
                        : 'Create Event'
                }
                onHide={onCloseEventDialog}
                visible={props.showEventDialog}
                position="right"
            >
                <EventForm
                    formID={CREATE_FORM_ID}
                    eventID={selectedEvent?.eventID}
                    onSubmit={handleEventSubmit}
                />
            </Sidebar>

            {showImportModal && (
                <SpreadsheetImport
                    isOpen={showImportModal}
                    onClose={() => setShowImportModal(false)}
                    onSubmit={handleSubmit}
                    fields={importFields}
                />
            )}
            <Toast ref={eventSingleFormToast} />
        </>
    );
};

export default EventsView;
