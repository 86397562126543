import { useEffect, useMemo, useRef, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { Chart } from 'primereact/chart';
import { unionBy, uniq } from 'lodash';

import { useGetEventsQuery } from '../../api/events';
import {
    useGetTeamOwnedSeasonsQuery,
    useGetTeamParticipatingSeasonsQuery,
} from '../../api/seasons';
import { useGetTeamSeasonInterchangeStatTimeSeriesQuery } from '../../api/reports';
import { useGetStatsQuery } from '../../api/stats';

import Icon from '../../components/Icon';
import PageHeader from '../../layout/PageHeader';
import playLogo from '../../assets/images/logos/rm-play-logo.png';
import { Image } from 'primereact/image';

import { periodSuffix } from '../../util/helper';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { SelectButton } from 'primereact/selectbutton';
import { SelectItem } from 'primereact/selectitem';
import { Toolbar } from 'primereact/toolbar';

import { toISOStringWithTimezone } from '../../util/helper';
import { defaultReportState } from '../reports/constants';

import { Event } from '../../types/event';
import { ReportDataTypes, ReportState, TeamStat } from '../../types/reports';
import { useGetTeamQuery } from '../../api/teams';
import ErrorDisplay from '../../components/ErrorDisplay';
import { BaseEntityType, ERROR_TYPES } from '../../types/common';
import RookieButton from '../../components/RookieButton';
import PageContainer from '../../layout/PageContainer';

import { Mixpanel } from '../../util/mixpanel';

interface Props {
    reportType: string;
}

const TeamStatDensityReport = ({ reportType }: Props) => {
    // Route Params
    const { teamID } = useParams();

    // Search Params
    const [searchParams] = useSearchParams();
    const eventParam = searchParams.get('eventID');
    const seasonParam = searchParams.get('seasonID');

    // State Hooks
    const [dataType, setDataType] = useState(ReportDataTypes.Total);
    const [isLoading, setIsLoading] = useState(true);
    const [season, setSeason] = useState<string | null>(seasonParam || null);
    const [event, setEvent] = useState<string>(eventParam || '');
    const [statType, setStatType] = useState(); // Set to a default value
    const [timeSeriesReportData, setTimeSeriesReportData] =
        useState<ReportState<TeamStat[]>>(defaultReportState);

    const [filter, setFilter] = useState(0); // for period filter

    // Cache busting ref
    const timestampRef = useRef(Date.now()).current;
    const dataTable = useRef<DataTable<any>>(null);

    // API Hooks
    const teamData = useGetTeamQuery(
        { teamID: teamID || '' },
        { skip: !teamID }
    );
    const team = teamData?.data?.data;

    const seasonOwnedData = useGetTeamOwnedSeasonsQuery(
        {
            teamID: teamID || '',
            cursor: '',
        },
        { skip: !teamID }
    );

    const seasonParticipatingData = useGetTeamParticipatingSeasonsQuery(
        {
            teamID: teamID || '',
            cursor: '',
        },
        { skip: !teamID }
    );

    const eventData = useGetEventsQuery({
        entityType: BaseEntityType.teams,
        entityID: teamID || '',
        to: toISOStringWithTimezone(new Date()),
        limit: '50',
    });

    const requestStatTimeSeriesData =
        useGetTeamSeasonInterchangeStatTimeSeriesQuery(
            {
                seasonID: season || '',
                teamID: teamID || '',
                sessionID: timestampRef,
                concatenated: true,
            },
            {
                skip: !season || !teamID,
            }
        );

    // Helpers
    const mergedSeasons = useMemo(() => {
        const ownedSeasons = seasonOwnedData?.data?.data;
        const participatingSeasons = seasonParticipatingData?.data?.data;

        return ownedSeasons && participatingSeasons
            ? unionBy(ownedSeasons, participatingSeasons, 'seasonID')
            : [];
    }, [seasonOwnedData, seasonParticipatingData]);

    useEffect(() => {
        const reportUrl = requestStatTimeSeriesData?.data?.data.objectURL;
        if (reportUrl && requestStatTimeSeriesData.isSuccess) {
            setTimeSeriesReportData((prev) => ({
                ...prev,
                data: [],
                error: null,
                isError: false,
                isUninitialized: false,
            }));

            // Fetch report
            fetch(reportUrl)
                .then((response) => {
                    return response.json();
                })
                .then((data) => {
                    // Update report data and set isLoading to false
                    setTimeSeriesReportData((prev) => ({
                        ...prev,
                        data,
                        isError: false,
                    }));
                    setIsLoading(false);
                })
                .catch((err) => {
                    // Handle error and set isLoading to false
                    setTimeSeriesReportData((prev) => ({
                        ...prev,
                        error: err,
                        isError: true,
                    }));
                    setIsLoading(false);
                });
        } else {
            setTimeSeriesReportData(defaultReportState);
        }
    }, [requestStatTimeSeriesData]);

    const statConfigData = useGetStatsQuery({
        sportID: team?.entitySportID as string,
    });

    const statConfig = statConfigData?.data?.data;

    // Filter Options
    const eventOptions = useMemo(() => {
        let options = [
            {
                label: 'Entire Season',
                value: '',
                error: false,
            },
        ];

        const availableGames = uniq(
            timeSeriesReportData?.data?.map((val: TeamStat) => val.gameId)
        );

        if (eventData.data) {
            eventData.data.data.forEach((event: Event) => {
                options.push({
                    label: event.eventName,
                    value: event.eventID,
                    error: !availableGames.includes(event.eventID),
                });
            });
        }

        // Filter out options where error is true
        return options.filter((option) => !option.error);
    }, [timeSeriesReportData, eventData]);

    const [rawTimeSeriesData, setRawTimeSeriesData] = useState<TeamStat[]>([]);

    useEffect(() => {
        // Check if timeSeriesReportData and statConfig are available
        if (statConfig && timeSeriesReportData?.data) {
            const statIdToNameMap: Record<string, string> = {};

            // Map statIDs to their corresponding names
            statConfig.forEach((stat) => {
                statIdToNameMap[stat.statID] = stat.statName;
            });

            // Update rawTimeSeriesData based on the mapping
            const updatedRawTimeSeries: TeamStat[] =
                timeSeriesReportData.data.map((entry) => {
                    if (entry.statId && statIdToNameMap[entry.statId]) {
                        return {
                            ...entry,
                            statId: statIdToNameMap[entry.statId],
                        };
                    }
                    return entry;
                });

            setRawTimeSeriesData(updatedRawTimeSeries); // Update state
        } else {
        }
    }, [statConfig, timeSeriesReportData]);

    const statOptions = useMemo(() => {
        let options: SelectItem[] = [];

        if (!rawTimeSeriesData) {
            return options; // Return only the default 'All Stats' option if rawTimeSeriesData is undefined
        }

        // Extract unique statIDs from rawTimeSeriesData, filtering out null or undefined statId
        const uniqStats = Array.from(
            new Set(
                rawTimeSeriesData
                    .filter(
                        (item) =>
                            item.statId &&
                            (event === '' || item.gameId === event)
                    )
                    .map((item) => item.statId)
            )
        );

        // Map unique statIDs to the options
        uniqStats.forEach((statId) => {
            options.push({
                label: statId, // You may adjust how you want to label these
                value: statId,
            });
        });

        return options;
    }, [rawTimeSeriesData, event]);

    useEffect(() => {
        if (statOptions && statOptions.length > 0) {
            // Set statType to the first option in statOptions
            setStatType(statOptions[0].value);
        }
    }, [statOptions]);

    const seasonOptions = useMemo(() => {
        let options = mergedSeasons
            ? mergedSeasons.map((season) => ({
                  label: season.seasonName,
                  value: season.seasonID,
              }))
            : [];

        return options;
    }, [mergedSeasons]);

    // Set the season to the first available season
    useEffect(() => {
        if (!season && mergedSeasons.length > 0) {
            setSeason(team?.defaultSeasonID || '');
        }
    }, [mergedSeasons, season, team]);

    // Set data type to totals when filtering by a single event
    useEffect(() => {
        if (event !== '' && dataType === ReportDataTypes.Average) {
            setDataType(ReportDataTypes.Total);
        }
    }, [event, dataType]);

    const exportCSV = () => {
        dataTable.current && dataTable.current.exportCSV();

        Mixpanel.track('Export Report', {
            reportType: `${reportType} Cumulative Team Game Stats`,
        });
    };
    let totalPeriods = 0;
    let periodFilteredData: TeamStat[] = rawTimeSeriesData || [];
    if (event !== '') {
        totalPeriods = periodFilteredData.reduce(
            (maxPeriod, item: TeamStat) => {
                const itemPeriod =
                    typeof item.period === 'string'
                        ? parseInt(item.period, 10)
                        : item.period;
                return itemPeriod > maxPeriod ? itemPeriod : maxPeriod;
            },
            0
        );
    }

    const periodOptions = useMemo(
        () =>
            Array.from({ length: totalPeriods }).map((u, i) => {
                const period = i + 1;
                const label = `${period}${periodSuffix(Number(period))}`;
                return {
                    label: label,
                    value: period,
                };
            }),
        [totalPeriods]
    );

    // Renders
    const leftToolbar = (
        <div className="p-button-group">
            <>
                <Dropdown
                    onChange={(e) => setSeason(e.value)}
                    value={season}
                    options={seasonOptions}
                />
                <Dropdown
                    onChange={(e) => setEvent(e.value)}
                    value={event}
                    options={eventOptions}
                    itemTemplate={(o) => {
                        return (
                            <div
                                style={{
                                    opacity: o.error ? 0.5 : 1,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                }}
                            >
                                {o.label} {o.error && <Icon name="error" />}
                            </div>
                        );
                    }}
                />
                {statOptions.length > 1 && (
                    <Dropdown
                        onChange={(e) => setStatType(e.value)}
                        value={statType}
                        options={statOptions}
                    />
                )}
                <div className="p-buttonset">
                    <SelectButton
                        value={filter}
                        multiple={false}
                        allowEmpty={false}
                        onChange={(e) => setFilter(e.value)}
                        options={[{ label: 'All', value: 0 }, ...periodOptions]}
                        disabled={event === ''}
                    />
                </div>
            </>
        </div>
    );
    const rightToolbar = (
        <div className="p-button-group">
            <RookieButton
                type="button"
                onClick={() => exportCSV()}
                label="Export CSV"
                severity="secondary"
            />
        </div>
    );

    const renderEmpty = () => {
        let msg;

        if (rawTimeSeriesData.length > 0 && filter !== 0) {
            msg = 'There are no stats for the selected period.';
        } else if (event === '') {
            msg = 'There are no complete matches for this team.';
        } else {
            msg = 'There is no data.';
        }
        const errorType = event
            ? ERROR_TYPES.somethingsWrong
            : ERROR_TYPES.notFound;

        return (
            <ErrorDisplay
                alignment="middle"
                title="No Data Found"
                desc={msg}
                errorType={errorType}
                hasReturn={false}
                proportion="compact"
            />
        );
    };

    const tableFooter = (
        <div className="table-disclaimer">
            <span>Report generated by</span>{' '}
            <Image height="24px" src={playLogo} alt="Rookie Me Play" />
        </div>
    );

    // Here is where you create the dataset for the chart based on the filtering above.
    const [chartData, setChartData] = useState({});
    const [chartOptions, setChartOptions] = useState({});

    useEffect(() => {
        const documentStyle = getComputedStyle(document.documentElement);
        const textColor = documentStyle.getPropertyValue('--text-color');
        const textColorSecondary = documentStyle.getPropertyValue(
            '--text-color-secondary'
        );
        const surfaceBorder =
            documentStyle.getPropertyValue('--surface-border');

        // Create labels for bins (0 to 1, incrementing by 0.1)
        const binsForMath = 20; // set number of bins, 20 = going up in 5% incremenets 10 = 10% increments
        const bins = Array.from({ length: binsForMath }, (_, i) =>
            (i / 10).toFixed(1)
        );
        const teamBinCounts = Array(bins.length).fill(0);
        const oppositionBinCounts = Array(bins.length).fill(0);

        // Filter rawTimeSeriesData for team and opposition values
        const teamData = rawTimeSeriesData
            ? rawTimeSeriesData
                  .filter(
                      (item) =>
                          item.statId === statType &&
                          (event !== '' ? item.gameId === event : true) &&
                          (filter !== 0 ? item.period === filter : true) &&
                          !item.value.includes('_opponent') &&
                          item.name.includes('teamID')
                  )
                  .map((item) =>
                      filter !== 0 ? item.percOfPeriod : item.percOfGame
                  )
            : [];

        const oppositionData = rawTimeSeriesData
            ? rawTimeSeriesData
                  .filter(
                      (item) =>
                          item.statId === statType &&
                          (event !== '' ? item.gameId === event : true) &&
                          (filter !== 0 ? item.period === filter : true) &&
                          item.value.includes('_opponent')
                  )
                  .map((item) =>
                      filter !== 0 ? item.percOfPeriod : item.percOfGame
                  )
            : [];

        // Calculate bin counts for team
        teamData.forEach((value) => {
            const binIndex = Math.floor(value * binsForMath); // Bin index from 0 to 9

            if (binIndex >= 0 && binIndex < teamBinCounts.length) {
                teamBinCounts[binIndex]++;
            }
        });

        // Calculate bin counts for opposition
        oppositionData.forEach((value) => {
            const binIndex = Math.floor(value * binsForMath); // Bin index from 0 to 9
            if (binIndex >= 0 && binIndex < oppositionBinCounts.length) {
                oppositionBinCounts[binIndex]++;
            }
        });
        // Here we define the x axis labels if we are looking at a match of period
        const xLabels = [
            '0-5%',
            '6-10%',
            '11-15%',
            '16-20%',
            '21-25%',
            '26-30%',
            '31-35%',
            '36-40%',
            '41-45%',
            '46-50%',
            '51-55%',
            '56-60%',
            '61-65%',
            '66-70%',
            '71-75%',
            '76-80%',
            '81-85%',
            '86-90%',
            '91-95%',
            '96-100%',
        ];
        const data = {
            datasets: [
                {
                    label: `Team ${statType}`,
                    data: teamBinCounts.map((value, index) => ({
                        x: xLabels[index], // Use selected xLabels based on filter
                        y: value + 0.05, // Add a small y-offset
                    })),
                    tension: 0.2,
                    borderColor: '#ff6700',
                    backgroundColor: 'rgba(255,167,38,0.2)',

                    pointStyle: 'circle', // Unique point style
                },
                {
                    label: `Opposition ${statType}`,
                    data: oppositionBinCounts,
                    tension: 0.2,
                    borderColor: 'black',
                    backgroundColor: 'rgba(0,0,0,0.2)',
                },
            ],
        };

        const options = {
            maintainAspectRatio: false,
            aspectRatio: 0.6,
            plugins: {
                legend: {
                    xLabels: {
                        color: textColor,
                    },
                },
                tooltip: {
                    callbacks: {
                        label: function (context: any) {
                            const label = context.dataset.label || '';
                            const value =
                                context.raw.y !== undefined
                                    ? context.raw.y
                                    : context.raw;
                            return `${label}: ${Math.round(value)}`;
                        },
                    },
                },
            },
            scales: {
                x: {
                    title: {
                        display: true,
                        text:
                            filter !== 0
                                ? 'Percentage of Period Selected'
                                : 'Percentage of Game',
                        color: textColor,
                        font: {
                            size: 16,
                            weight: 'bold',
                        },
                    },
                    ticks: {
                        color: textColorSecondary,
                    },
                    grid: {
                        color: surfaceBorder,
                    },
                },
                y: {
                    title: {
                        display: true,
                        text: `Count of ${statType}`,
                        color: textColor,
                        font: {
                            size: 16,
                            weight: 'bold',
                        },
                    },
                    ticks: {
                        color: textColorSecondary,
                    },
                    grid: {
                        color: surfaceBorder,
                    },
                },
            },
        };
        if (
            timeSeriesReportData.data === undefined ||
            timeSeriesReportData.data.length === 0
        ) {
            setIsLoading(false);
        }

        if (teamData.length > 0 || oppositionData.length > 0) {
            setChartData(data);
        } else {
            setChartData([]);
        }

        setChartOptions(options);
    }, [rawTimeSeriesData, statType, event, filter, timeSeriesReportData]);

    return (
        <PageContainer>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <PageHeader title="Stat Density" />
                <RookieButton
                    style={{
                        backgroundColor: 'transparent',
                        border: 'none',
                        color: 'inherit',
                        opacity: 'inherit',
                        marginLeft: '-110px', // Use a negative margin to reduce space
                        marginTop: '50px',
                    }}
                    text={true}
                    data-beacon-article="6723f138d0576266aafb792a"
                    icon="help"
                />
            </div>
            <Toolbar start={leftToolbar} end={rightToolbar} />
            {isLoading ? (
                <div>Loading...</div>
            ) : rawTimeSeriesData === undefined ||
              rawTimeSeriesData.length === 0 ||
              Array.isArray(chartData) ? (
                renderEmpty()
            ) : (
                <div style={{ maxHeight: 'auto' }}>
                    <Chart
                        type="line"
                        data={chartData}
                        options={chartOptions}
                    />
                    {(filter === 0 &&
                        team?.entitySportID === 'aus_football_au') ||
                    (filter === 0 && team?.entitySportID === 'netb_au') ? (
                        <span>
                            <strong>
                                Note: 25% would be the end of period 1, 50% end
                                of Q2, 75% Q3 and 100% Q4
                            </strong>
                        </span>
                    ) : null}
                    {tableFooter}
                </div>
            )}
        </PageContainer>
    );
};

export default TeamStatDensityReport;
