import React from 'react';
import { Formik, FormikErrors, FormikHelpers, FormikValues } from 'formik';
import { useParams } from 'react-router-dom';
import { pick } from 'lodash';

import { useUpsertPlayerMutation } from '../../api/players';

import { getDirtyValues, toISOStringWithoutTimezone } from '../../util/helper';

import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { ProgressBar } from 'primereact/progressbar';
import { RadioButton } from 'primereact/radiobutton';

import FormActions from '../../components/FormActions';
import FormFields from '../../components/FormFields';
import FormGroup from '../../components/FormGroup';
import RookieButton from '../../components/RookieButton';

import { INPUT_TYPES } from '../../types/common';
import { ColourOptions } from '../../types/design';
import { PlayerForm as PlayerFormTypes, Player } from '../../types/team';

import { Mixpanel } from '../../util/mixpanel';

interface Props {
    formHasActions?: boolean;
    initialValues: PlayerFormTypes;
    playerID?: Player['playerID'];
    onCancel?: Function;
    onSubmit?: Function;
}

const requiredFields: Array<keyof PlayerFormTypes> = [
    'gender',
    'firstName',
    'lastName',
    'displayName',
    'uniformNumber',
];

const availableFields: Array<keyof PlayerFormTypes> = [
    'gender',
    'firstName',
    'lastName',
    'displayName',
    'uniformNumber',
    'dateOfBirth',
    'colour',
    'image',
];

// set colour options, pull out keys for mapping
const colourOptions = pick(ColourOptions, [
    'Black',
    'Green',
    'Teal',
    'Maroon',
    'Yellow',
    'VegasGold',
    'White',
]);
const colourKeys = Object.values(colourOptions);

const PlayerForm = (props: Props) => {
    const { formHasActions, initialValues, onSubmit, playerID } = props;

    const { teamID } = useParams();
    const [upsertPlayer] = useUpsertPlayerMutation();

    // Set DOB limits
    let playerDOBMinDate = new Date();
    playerDOBMinDate.setFullYear(playerDOBMinDate.getFullYear() - 100);

    const playerDOBMin = toISOStringWithoutTimezone(playerDOBMinDate);
    const playerDOBMax = toISOStringWithoutTimezone(new Date());

    const handleSubmit = (
        data: PlayerFormTypes,
        { resetForm, setSubmitting }: FormikHelpers<PlayerFormTypes>
    ) => {
        const isCreate = !playerID;

        // Ensure dirtyValues is correctly typed
        const dirtyValues: Partial<PlayerFormTypes> = getDirtyValues(
            data,
            initialValues
        );

        // If it's a create operation, ensure required fields are included
        if (isCreate) {
            dirtyValues.startDate = toISOStringWithoutTimezone(new Date());
            dirtyValues.endDate = '';
        }

        if (Object.keys(dirtyValues).length === 0) {
            // No changes detected, prevent unnecessary API call
            setSubmitting(false);
            return;
        }

        setSubmitting(true);

        upsertPlayer({ teamID, playerID, ...dirtyValues })
            .then(() => {
                Mixpanel.track(`${playerID ? 'Update' : 'Create'} Player`);
            })
            .catch(() => {
                onSubmit && onSubmit('error');
            })
            .finally(() => {
                setSubmitting(false);
                playerID
                    ? onSubmit && onSubmit('updated')
                    : onSubmit && onSubmit('success');
                resetForm();
            });
    };

    const validate = (values: FormikValues) => {
        const errors: FormikErrors<PlayerFormTypes> = {};

        requiredFields.forEach((field) => {
            if (!values[field]) {
                errors[field as keyof PlayerFormTypes] =
                    'Field cannot be blank';
            }
        });

        return errors;
    };
    return (
        <Formik
            initialValues={pick(initialValues, availableFields)}
            validate={validate}
            onSubmit={handleSubmit}
            enableReinitialize
        >
            {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
                isSubmitting,
            }) => {
                const handleNameChange = (
                    e: React.ChangeEvent<HTMLInputElement>
                ) => {
                    const { name, value } = e.target;
                    setFieldValue(name, value);

                    // Dynamically update displayName based on firstName and lastName
                    if (name === 'firstName' || name === 'lastName') {
                        const firstNameValue =
                            name === 'firstName' ? value : values.firstName;
                        const lastNameValue =
                            name === 'lastName' ? value : values.lastName;

                        if (firstNameValue && lastNameValue) {
                            const firstInitial = firstNameValue
                                .charAt(0)
                                .toUpperCase();
                            setFieldValue(
                                'displayName',
                                `${firstInitial}. ${lastNameValue}`
                            );
                        } else {
                            setFieldValue('displayName', ''); // Reset displayName if either is empty
                        }
                    }
                };
                return (
                    <form onSubmit={handleSubmit}>
                        <FormFields>
                            <FormGroup
                                label="Player Image (optional)"
                                htmlFor="playerImage"
                            >
                                <div className="player-image-container">
                                    <InputText
                                        id="playerImage"
                                        name="playerImage"
                                        type="file"
                                        accept="image/*"
                                        style={{ width: '100%' }}
                                        onChange={(
                                            e: React.ChangeEvent<HTMLInputElement>
                                        ) => {
                                            const file = e.target.files?.[0];
                                            if (file) {
                                                const reader = new FileReader();
                                                reader.onload = () => {
                                                    setFieldValue(
                                                        'image',
                                                        reader.result
                                                    );
                                                };
                                                reader.readAsDataURL(file);
                                            }
                                        }}
                                    />
                                </div>
                            </FormGroup>
                            <FormGroup
                                label="First Name"
                                htmlFor="firstName"
                                error={errors.firstName}
                                showError={
                                    !!errors.firstName && touched.firstName
                                }
                            >
                                <InputText
                                    id="firstName"
                                    name="firstName"
                                    type={INPUT_TYPES.text}
                                    onChange={handleNameChange}
                                    onBlur={handleBlur}
                                    placeholder="Enter First Name"
                                    value={values.firstName}
                                    required
                                />
                            </FormGroup>
                            <FormGroup
                                label="Last Name"
                                htmlFor="lastName"
                                error={errors.lastName}
                                showError={
                                    !!errors.lastName && touched.lastName
                                }
                            >
                                <InputText
                                    id="lastName"
                                    name="lastName"
                                    type={INPUT_TYPES.text}
                                    onChange={handleNameChange}
                                    onBlur={handleBlur}
                                    placeholder="Enter Last Name"
                                    value={values.lastName}
                                    required
                                />
                            </FormGroup>
                            <FormGroup
                                label="Display Name"
                                htmlFor="displayName"
                                error={errors.displayName}
                                showError={
                                    !!errors.displayName && touched.displayName
                                }
                            >
                                <InputText
                                    id="displayName"
                                    name="displayName"
                                    type={INPUT_TYPES.text}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    placeholder="Enter Display Name"
                                    value={values.displayName}
                                    required
                                />
                            </FormGroup>
                            <FormGroup
                                label="Playing Number"
                                htmlFor="uniformNumber"
                                error={errors.uniformNumber}
                                showError={
                                    !!errors.uniformNumber &&
                                    touched.uniformNumber
                                }
                            >
                                <InputText
                                    id="uniformNumber"
                                    name="uniformNumber"
                                    type={INPUT_TYPES.number}
                                    onInput={(
                                        e: React.ChangeEvent<HTMLInputElement>
                                    ) => {
                                        const maxLength = 3;
                                        const val =
                                            e.target.value.length > maxLength
                                                ? e.target.value.slice(
                                                      0,
                                                      maxLength
                                                  )
                                                : e.target.value;
                                        setFieldValue('uniformNumber', val);
                                    }}
                                    onBlur={handleBlur}
                                    placeholder="Enter Playing Number"
                                    value={values.uniformNumber}
                                    required
                                />
                            </FormGroup>
                            <FormGroup
                                label="Gender"
                                htmlFor="gender"
                                error={errors.gender}
                                showError={!!errors.gender && touched.gender}
                            >
                                <Dropdown
                                    id="gender"
                                    name="gender"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    placeholder="Player's gender"
                                    value={values?.gender}
                                    options={[
                                        { label: 'Male', value: 'Male' },
                                        {
                                            label: 'Female',
                                            value: 'Female',
                                        },
                                    ]}
                                />
                            </FormGroup>
                            <FormGroup
                                label="Date Of Birth (optional)"
                                htmlFor="dateOfBirth"
                                error={values.dateOfBirth}
                                showError={
                                    !!errors.dateOfBirth && touched.dateOfBirth
                                }
                            >
                                <InputText
                                    id="dateOfBirth"
                                    placeholder="DD/MM/YY"
                                    name="dateOfBirth"
                                    type="date"
                                    min={playerDOBMin}
                                    max={playerDOBMax}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.dateOfBirth}
                                />
                            </FormGroup>
                            <FormGroup label="Colour (optional)">
                                <div className="swatches">
                                    {colourKeys.map((colour) => {
                                        return (
                                            <RadioButton
                                                key={colour}
                                                className="swatch-option"
                                                inputId={`colour-${colour}`}
                                                name="colour"
                                                value={colour}
                                                onChange={handleChange}
                                                checked={
                                                    values.colour === colour
                                                }
                                                style={
                                                    {
                                                        '--swatch-colour':
                                                            colour,
                                                    } as React.CSSProperties
                                                }
                                            />
                                        );
                                    })}
                                </div>
                            </FormGroup>
                        </FormFields>
                        {formHasActions && (
                            <FormActions
                                start={
                                    <RookieButton
                                        type="button"
                                        label="Cancel"
                                        severity="secondary"
                                        disabled={isSubmitting}
                                        onClick={() =>
                                            props.onCancel && props.onCancel()
                                        }
                                    />
                                }
                                end={
                                    <RookieButton
                                        type="submit"
                                        disabled={isSubmitting}
                                        loading={isSubmitting}
                                        label={
                                            playerID
                                                ? 'Update Player'
                                                : 'Save Player'
                                        }
                                    />
                                }
                            ></FormActions>
                        )}
                        {isSubmitting && (
                            <div className="form-overlay">
                                <ProgressBar mode="indeterminate" />
                            </div>
                        )}
                    </form>
                );
            }}
        </Formik>
    );
};

PlayerForm.defaultProps = {
    initialValues: {
        gender: 'Male',
        firstName: '',
        lastName: '',
        colour: colourKeys[0],
        displayName: '',
        uniformNumber: '',
        dateOfBirth: undefined,
    },
    formHasActions: true,
};

export default PlayerForm;
