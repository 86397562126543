import { groupBy } from 'lodash';
import { GameSummary } from '../../types/reports';
import Icon from '../../components/Icon';
import { Tag } from 'primereact/tag';
import { Column } from 'primereact/column';
import { Tooltip } from 'primereact/tooltip';
import { Skeleton } from 'primereact/skeleton';
import RookieButton from '../../components/RookieButton'; // Update this import as needed

interface Data {
    [key: string]: any;
}

export const calculateAverages = (data: Data, group: string, count: number) => {
    return Object.values(groupBy(data, group)).map((item) => {
        const totals = item.reduce((result, curr) => {
            Object.keys(curr).forEach((k) => {
                if (curr.hasOwnProperty(k)) {
                    result[k] =
                        typeof curr[k] === 'number'
                            ? (result[k] || 0) + curr[k]
                            : curr[k];
                }
            });
            return result;
        }, {});

        let averages: Data = {};
        Object.keys(totals).forEach((k) => {
            averages[k] =
                typeof totals[k] === 'number' ? totals[k] / count : totals[k];
        });

        return averages;
    });
};

export const calculateTotals = (data: Data, group: string) => {
    return Object.values(groupBy(data, group)).map((item) => {
        return item.reduce((result, curr) => {
            Object.keys(curr).forEach((k) => {
                result[k] =
                    typeof curr[k] === 'number'
                        ? (result[k] || 0) + curr[k]
                        : curr[k];
            });
            return result;
        }, {});
    });
};



// Define the new Result column logic
export const renderResult = (rowData: GameSummary) => {
    if (rowData.wins === 1) {
        return (
            <Icon
                name="check"
                size="large"
                style={{
                    color: 'white',
                    backgroundColor: 'green',
                    borderRadius: '50%',
                }}
            />
        );
    } else if (rowData.losses === 1) {
        return (
            <Icon
                name="close"
                size="large"
                style={{
                    color: 'white',
                    backgroundColor: 'red',
                    borderRadius: '50%',
                }}
            />
        );
    } else if (rowData.draws === 1) {
        return (
            <Tag
                style={{
                    width: '30px',
                    height: '30px',
                    color: '#fff',
                    background: '#808080',
                }}
            >
                D
            </Tag>
        );
    } else {
        return null; // Handle default case if needed
    }
};

export function getScoringAccuracyColumn(
    team: any,
    stat: any,
    array: any[],
    reportData: any,
    showInfo: boolean,
    setShowInfo: (show: boolean) => void
) {
    // Check if sport ID is relevant and stat name matches "Missed Shot"
    if (
        team?.entitySportID === 'aus_football_au' &&
        stat.statName === 'Missed Shot'
    ) {
        return (
            <Column
                field="afl_scoring_accuracy"
                key="afl_scoring_accuracy"
                align="center"
                alignHeader="center"
                header={
                    <>
                        <Tooltip
                            target=".cell-scoringAccuracy"
                            autoHide={false}
                            position="right"
                            onBeforeHide={() => setShowInfo(false)}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                <div style={{ color: '#fff' }}>
                                    <div>Scoring Accuracy</div>
                                    {showInfo && (
                                        <div>
                                            Successful shots on goal divided by
                                            total shots taken.
                                        </div>
                                    )}
                                </div>
                                <RookieButton
                                    onClick={() => setShowInfo(!showInfo)}
                                    icon={showInfo ? 'close' : 'info'}
                                />
                            </div>
                        </Tooltip>
                        SAC
                    </>
                }
                headerStyle={{ backgroundColor: 'white' }}
                headerClassName="cell-scoringAccuracy"
                sortable
                body={(row) => {
                    const goalStats = array.filter(
                        (s) => s.statName === 'Goal'
                    );
                    const behindStats = array.filter(
                        (s) => s.statName === 'Behind'
                    );
                    const missedShotStats = array.filter(
                        (s) => s.statName === 'Missed Shot'
                    );

                    const goals = goalStats.reduce(
                        (sum, stat) => sum + (row[stat.statID] || 0),
                        0
                    );
                    const behinds = behindStats.reduce(
                        (sum, stat) => sum + (row[stat.statID] || 0),
                        0
                    );
                    const missedshots = missedShotStats.reduce(
                        (sum, stat) => sum + (row[stat.statID] || 0),
                        0
                    );

                    let displayAccuracy;
                    if (goals === 0 && (behinds > 0 || missedshots > 0)) {
                        displayAccuracy = 0;
                    } else if (goals + behinds + missedshots > 0) {
                        displayAccuracy =
                            (goals / (goals + behinds + missedshots)) * 100;
                    } else {
                        displayAccuracy = null;
                    }

                    return !row || reportData.isLoading ? (
                        <Skeleton width="2rem" height="1rem" />
                    ) : displayAccuracy !== null ? (
                        `${displayAccuracy.toFixed(1)}%`
                    ) : (
                        '-'
                    );
                }}
                exportable={false}
            />
        );
    } else if (
        team?.entitySportID === 'bball_au' &&
        stat.statName === 'Free Throw Miss'
    ) {
        return (
            <Column
                field="bball_freethrow_accuracy"
                key="bball_freethrow_accuracy"
                align="center"
                alignHeader="center"
                header={
                    <>
                        <Tooltip
                            target=".cell-scoringFTAccuracy"
                            autoHide={false}
                            position="right"
                            onBeforeHide={() => setShowInfo(false)}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                <div style={{ color: '#fff' }}>
                                    <div>Free Throw Accuracy</div>
                                    {showInfo && (
                                        <div>
                                            Percent accuracy of free throws.
                                        </div>
                                    )}
                                </div>
                                <RookieButton
                                    onClick={() => setShowInfo(!showInfo)}
                                    icon={showInfo ? 'close' : 'info'}
                                />
                            </div>
                        </Tooltip>
                        FT%
                    </>
                }
                headerStyle={{ backgroundColor: 'white' }}
                headerClassName="cell-scoringFTAccuracy"
                sortable
                body={(row) => {
                    const goalStats = array.filter(
                        (s) => s.statName === 'Free Throw Goal'
                    );

                    const missedShotStats = array.filter(
                        (s) => s.statName === 'Free Throw Miss'
                    );

                    const goals = goalStats.reduce(
                        (sum, stat) => sum + (row[stat.statID] || 0),
                        0
                    );

                    const missedshots = missedShotStats.reduce(
                        (sum, stat) => sum + (row[stat.statID] || 0),
                        0
                    );

                    let displayAccuracy;
                    if (goals === 0 && missedshots > 0) {
                        displayAccuracy = 0;
                    } else if (goals + missedshots > 0) {
                        displayAccuracy = (goals / (goals + missedshots)) * 100;
                    } else {
                        displayAccuracy = null;
                    }

                    return !row || reportData.isLoading ? (
                        <Skeleton width="2rem" height="1rem" />
                    ) : displayAccuracy !== null ? (
                        `${displayAccuracy.toFixed(1)}%`
                    ) : (
                        '-'
                    );
                }}
                exportable={false}
            />
        );
    } else if (
        team?.entitySportID === 'bball_au' &&
        stat.statName === 'Field Goal Miss'
    ) {
        return (
            <Column
                field="bball_fieldgoal_accuracy"
                key="bball_fieldgoal_accuracy"
                align="center"
                alignHeader="center"
                header={
                    <>
                        <Tooltip
                            target=".cell-FGscoringAccuracy"
                            autoHide={false}
                            position="right"
                            onBeforeHide={() => setShowInfo(false)}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                <div style={{ color: '#fff' }}>
                                    <div>Field Goal Accuracy</div>
                                    {showInfo && (
                                        <div>
                                            Percent accuracy of field goals.
                                        </div>
                                    )}
                                </div>
                                <RookieButton
                                    onClick={() => setShowInfo(!showInfo)}
                                    icon={showInfo ? 'close' : 'info'}
                                />
                            </div>
                        </Tooltip>
                        FG%
                    </>
                }
                headerStyle={{ backgroundColor: 'white' }}
                headerClassName="cell-FGscoringAccuracy"
                sortable
                body={(row) => {
                    const goalStats = array.filter(
                        (s) => s.statName === 'Field Goal'
                    );

                    const missedShotStats = array.filter(
                        (s) => s.statName === 'Field Goal Miss'
                    );

                    const goals = goalStats.reduce(
                        (sum, stat) => sum + (row[stat.statID] || 0),
                        0
                    );

                    const missedshots = missedShotStats.reduce(
                        (sum, stat) => sum + (row[stat.statID] || 0),
                        0
                    );

                    let displayAccuracy;
                    if (goals === 0 && missedshots > 0) {
                        displayAccuracy = 0;
                    } else if (goals + missedshots > 0) {
                        displayAccuracy = (goals / (goals + missedshots)) * 100;
                    } else {
                        displayAccuracy = null;
                    }

                    return !row || reportData.isLoading ? (
                        <Skeleton width="2rem" height="1rem" />
                    ) : displayAccuracy !== null ? (
                        `${displayAccuracy.toFixed(1)}%`
                    ) : (
                        '-'
                    );
                }}
                exportable={false}
            />
        );
    } else if (
        team?.entitySportID === 'bball_au' &&
        stat.statName === '3-Point Miss'
    ) {
        return (
            <Column
                field="bball_3ptgoal_accuracy"
                key="bball_3ptgoal_accuracy"
                align="center"
                alignHeader="center"
                header={
                    <>
                        <Tooltip
                            target=".cell-3pscoringAccuracy"
                            autoHide={false}
                            position="right"
                            onBeforeHide={() => setShowInfo(false)}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                <div style={{ color: '#fff' }}>
                                    <div>3 Point Accuracy</div>
                                    {showInfo && (
                                        <div>
                                            Percent accuracy of 3 point shots.
                                        </div>
                                    )}
                                </div>
                                <RookieButton
                                    onClick={() => setShowInfo(!showInfo)}
                                    icon={showInfo ? 'close' : 'info'}
                                />
                            </div>
                        </Tooltip>
                        3P%
                    </>
                }
                headerStyle={{ backgroundColor: 'white' }}
                headerClassName="cell-3pscoringAccuracy"
                sortable
                body={(row) => {
                    const goalStats = array.filter(
                        (s) => s.statName === '3-Point'
                    );

                    const missedShotStats = array.filter(
                        (s) => s.statName === '3-Point Miss'
                    );

                    const goals = goalStats.reduce(
                        (sum, stat) => sum + (row[stat.statID] || 0),
                        0
                    );

                    const missedshots = missedShotStats.reduce(
                        (sum, stat) => sum + (row[stat.statID] || 0),
                        0
                    );

                    let displayAccuracy;
                    if (goals === 0 && missedshots > 0) {
                        displayAccuracy = 0;
                    } else if (goals + missedshots > 0) {
                        displayAccuracy = (goals / (goals + missedshots)) * 100;
                    } else {
                        displayAccuracy = null;
                    }

                    return !row || reportData.isLoading ? (
                        <Skeleton width="2rem" height="1rem" />
                    ) : displayAccuracy !== null ? (
                        `${displayAccuracy.toFixed(1)}%`
                    ) : (
                        '-'
                    );
                }}
                exportable={false}
            />
        );
    }

    return null;
}
