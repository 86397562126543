import { useEffect, useMemo, useRef } from 'react';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import {
    Formik,
    FormikErrors,
    FormikHelpers,
    FormikProps,
    FormikValues,
    getIn,
} from 'formik';
import { intersection, isEmpty, pickBy, set } from 'lodash';

import {
    getDirtyValues,
    getEntityFromParam,
    toISOString,
    toISOStringWithTimezone,
} from '../../util/helper';
import { getRouteByID } from '../../routes/routes';

// Hooks
import useGooglePlaceAutoComplete, {
    GoogleLocation,
} from '../../hooks/googlePlaces';
import { useUpsertEventMutation, useGetEventQuery } from '../../api/events';
import { useGetTeamOwnedSeasonsQuery } from '../../api/seasons';

// Components
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { SelectButton } from 'primereact/selectbutton';

import FormActions from '../../components/FormActions';
import FormFields from '../../components/FormFields';
import FormGroup from '../../components/FormGroup';
import RookieButton from '../../components/RookieButton';

// Types
import { BaseEntityType } from '../../types/common';
import {
    EventFormData,
    EventStatus,
    EventType,
    LocationCategory,
} from '../../types/event';

import { Mixpanel } from '../../util/mixpanel';
import usePermission from '../../hooks/usePermission';
import { useGetTeamQuery } from '../../api/teams';

interface Props {
    eventID?: string;
    formID?: string;
    initialValues?: EventFormData;
    onSubmit?: Function;
}

// Constants
const availableEventTypes = Object.keys(EventType).map((type) => ({
    label: type,
    value: type,
}));

const today = new Date();

// set default start date to tomorrow 12pm
const defaultStartDateTime = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate() + 1,
    12,
    0,
    0
);

// set default end date to tomorrow 2pm
const defaultEndDateTime = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate() + 1,
    14,
    0,
    0
);

const availableFields = {
    Other: [
        'eventType',
        'eventName',
        'eventStatus',
        'startDateTime',
        'endDateTime',
        'arrivalDateTime',
        'description',
        'location',
        'locationExtraInfo',
    ],
    Game: [
        'seasonID',
        'eventType',
        'eventName',
        'eventStatus',
        'startDateTime',
        'description',
        'location',
        'game',
        'game.opponentTeam.teamName',
        'locationExtraInfo',
    ],
    Training: [
        'eventType',
        'eventName',
        'eventStatus',
        'seasonID',
        'startDateTime',
        'endDateTime',
        'arrivalDateTime',
        'description',
        'location',
        'locationExtraInfo',
    ],
};

const requiredFields = {
    Other: ['eventType', 'eventName', 'startDateTime', 'endDateTime'],
    Game: [
        'seasonID',
        'eventType',
        'eventName',
        'startDateTime',
        'endDateTime',
        'game',
        'game.opponentTeam.teamName',
    ],
    Training: [
        'seasonID',
        'eventType',
        'eventName',
        'startDateTime',
        'endDateTime',
    ],
};

const defaultEventValues: EventFormData = {
    eventType: EventType.Game,
    game: {
        isHomeTeam: true,
        opponentTeam: {
            teamName: '',
        },
    },
    seasonID: '',
    location: {
        placeID: '',
        latitude: 0,
        longitude: 0,
        locationName: '',
        suburb: '',
        state: '',
        city: '',
        country: '',
        category: LocationCategory.Used,
        streetNumber: '',
        route: '',
        formattedAddress: '',
        postCode: 0,
    },
    startDateTime: toISOString(defaultStartDateTime),
    endDateTime: toISOString(defaultEndDateTime),
};

const EventForm = ({ eventID, initialValues, onSubmit, formID }: Props) => {
    // Route Hooks
    const params = useParams();
    const navigate = useNavigate();
    const activeEntity = useMemo(() => getEntityFromParam(params), [params]);
    const isCreate = !eventID || eventID === 'new';
    const { isAdmin } = usePermission(params.teamID);

    // Ref Hooks
    const formikRef = useRef<FormikProps<EventFormData>>(null);
    const locationRef = useRef<HTMLInputElement>(null);

    // API Hooks
    const [upsertEvent] = useUpsertEventMutation();

    const { data, isLoading, isError, isFetching } = useGetEventQuery(
        {
            // @ts-expect-error entityType param may not exist
            entityType: activeEntity?.entityType,
            // @ts-expect-error entityID param may not exist
            entityID: activeEntity?.entityID,
            eventID: eventID || '',
        },
        { skip: !eventID || !activeEntity }
    );

    const { data: seasonsRawData } = useGetTeamOwnedSeasonsQuery(
        {
            teamID: activeEntity?.entityID || '',
            cursor: '',
        },
        {
            skip: activeEntity?.entityType !== BaseEntityType.teams,
        }
    );

    const seasons = seasonsRawData?.data;
    const teamData = useGetTeamQuery(
        { teamID: activeEntity?.entityID || '' },
        { skip: !activeEntity?.entityID }
    );
    // Set default season ID to the teamDefaultSeasonID
    if (seasons) {
        defaultEventValues.seasonID = teamData.data?.data.defaultSeasonID;
    }
    // Google Places
    const googleAutoComplete = useGooglePlaceAutoComplete();

    const setFormikLocation = (address: GoogleLocation) => {
        if (formikRef.current) {
            formikRef.current.setValues({
                ...formikRef.current.values,
                location: {
                    placeID: address.placeID,
                    latitude: address.latitude,
                    longitude: address.longitude,
                    locationName: address.name,
                    suburb: address.locality,
                    state: address.adminArea1Short,
                    city: address.adminArea1Long,
                    country: address.countryLong,
                    category: LocationCategory.Used,
                    streetNumber: address.streetNumberShort,
                    route: address.routeShort,
                    formattedAddress: address.formattedAddress,
                    postCode: Number(address.postalCode),
                },
            });
        }
    };

    useEffect(() => {
        let autoComplete: any;

        const onAddressSelect = async () => {
            const address = await googleAutoComplete.getFullAddress(
                autoComplete
            );

            setFormikLocation(address);
        };

        async function loadGoogleMaps() {
            // initialize the Google Place Autocomplete widget and bind it to an input element.

            if (locationRef && locationRef.current) {
                autoComplete = await googleAutoComplete.initAutoComplete(
                    locationRef.current,
                    onAddressSelect
                );
            }
        }

        loadGoogleMaps();
    }, [googleAutoComplete]);

    const getInitialValues = () => {
        let initVals: EventFormData = {
            ...defaultEventValues,
            ...initialValues,
        };

        if (data && data.data) {
            let {
                game,
                startDateTime,
                endDateTime,
                arrivalDateTime,
                ...otherData
            } = data.data;

            if (game) {
                initVals.game = {
                    isHomeTeam: game.isHomeTeam,
                    opponentTeam: {
                        teamName: game.opponentTeam?.teamName || 'Opponent',
                    },
                };
            }

            if (startDateTime) {
                initVals.startDateTime = toISOString(startDateTime);
            }

            if (endDateTime) {
                initVals.endDateTime = toISOString(endDateTime);
            }

            if (arrivalDateTime) {
                initVals.arrivalDateTime = toISOString(arrivalDateTime);
            }

            if (otherData.location) {
                initVals.tempLocation = otherData.location.formattedAddress;
            }

            initVals = {
                ...initVals,
                ...otherData,
            };
        }

        return initVals;
    };

    const handleSubmit = (
        values: EventFormData,
        helpers: FormikHelpers<EventFormData>
    ) => {
        const initialValues = getInitialValues();
        const formData: EventFormData = isCreate
            ? values
            : getDirtyValues(values, initialValues);

        let payload = formData;

        // If creating a new event, add the default event status
        if (isCreate) {
            payload.eventStatus = EventStatus.Published;
        }

        if (formData.startDateTime) {
            payload.startDateTime = toISOStringWithTimezone(
                formData.startDateTime
            );
        }

        if (formData.endDateTime) {
            payload.endDateTime = toISOStringWithTimezone(formData.endDateTime);
        }

        if (formData.arrivalDateTime) {
            payload.arrivalDateTime = toISOStringWithTimezone(
                formData.arrivalDateTime
            );
        }

        if (formData.location) {
            const { eventID, ...otherLocation } = formData.location;
            payload.location = otherLocation;
        }

        const data = pickBy(payload, (value, key) => {
            // Filter data based on available fields
            return availableFields[
                values.eventType as keyof typeof availableFields
            ].includes(key);
        });

        helpers.setSubmitting(true);

        if (activeEntity) {
            upsertEvent({
                entityType: activeEntity.entityType,
                entityID: activeEntity.entityID,
                eventID,
                data,
            })
                .then((response) => {
                    Mixpanel.track(`${eventID ? 'Update' : 'Create'} Event`);
                    if (onSubmit) {
                        onSubmit(isCreate, response);
                    }
                })
                .catch((err) => {
                    console.warn('create error', err);
                })
                .finally(() => {
                    helpers.setSubmitting(false);
                });
        }
    };

    const validate = (values: FormikValues) => {
        const eventType = values.eventType;
        let errors: FormikErrors<EventFormData> = {};

        const required = intersection(
            requiredFields[eventType as keyof typeof requiredFields],
            availableFields[eventType as keyof typeof availableFields]
        );

        required.forEach((field) => {
            if (!getIn(values, field)) {
                set(errors, field, 'Field cannot be blank');
            }
        });

        if (
            required.includes('endDateTime') &&
            values.endDateTime < values.startDateTime
        ) {
            errors['endDateTime'] = 'End date must be after the start date';
        }

        return errors;
    };

    if (eventID && (isLoading || isFetching || isError)) {
        return <div>Loading</div>;
    }

    return (
        <Formik
            innerRef={formikRef}
            initialValues={getInitialValues()}
            onSubmit={handleSubmit}
            validate={validate}
        >
            {({
                errors,
                touched,
                dirty,
                handleChange,
                handleSubmit,
                handleBlur,
                isSubmitting,
                setFieldValue,
                setFieldTouched,
                setValues,
                values,
            }) => {
                return (
                    <form
                        id={formID}
                        className={'p-fluid form'}
                        onSubmit={handleSubmit}
                    >
                        <FormFields>
                            {isCreate && params.teamID && (
                                <FormGroup
                                    label="Event Type"
                                    htmlFor="eventType"
                                >
                                    <Dropdown
                                        id="eventType"
                                        name="eventType"
                                        value={values.eventType}
                                        options={availableEventTypes}
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                            )}
                            {/* EventName */}
                            <FormGroup
                                showError={
                                    !!errors.eventName && touched.eventName
                                }
                                error={errors.eventName}
                                htmlFor="eventName"
                                label="Event Title"
                            >
                                <InputText
                                    id="eventName"
                                    name="eventName"
                                    className={
                                        errors.eventName && touched.eventName
                                            ? 'p-invalid'
                                            : ''
                                    }
                                    placeholder="Event Title"
                                    required
                                    onInput={handleChange}
                                    onBlur={handleBlur}
                                    value={values.eventName}
                                />
                            </FormGroup>
                            {/* Season */}
                            {(values.eventType === 'Game' ||
                                values.eventType === 'Training') && (
                                <FormGroup
                                    label="Season"
                                    htmlFor="seasonID"
                                    error={errors.seasonID}
                                    showError={
                                        !!errors.seasonID && touched.seasonID
                                    }
                                >
                                    <Dropdown
                                        id="seasonID"
                                        name="seasonID"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.seasonID}
                                        emptyMessage={
                                            <div className="error-display">
                                                <p
                                                    style={{
                                                        marginBottom: '10px',
                                                        fontWeight: 'bold',
                                                    }}
                                                >
                                                    No Seasons found
                                                </p>

                                                <RookieButton
                                                    severity="secondary"
                                                    label="Add Season"
                                                    icon="add"
                                                    onClick={() => {
                                                        const url =
                                                            params.organisationID &&
                                                            params.teamID
                                                                ? getRouteByID(
                                                                      `organisations-teams-seasons`
                                                                  )
                                                                : getRouteByID(
                                                                      `${activeEntity?.entityType}-seasons`
                                                                  );

                                                        if (url) {
                                                            navigate(
                                                                generatePath(
                                                                    url.path,
                                                                    params
                                                                )
                                                            );
                                                        }
                                                    }}
                                                />
                                            </div>
                                        }
                                        required
                                        options={
                                            seasons
                                                ? seasons.map((season) => ({
                                                      label: season.seasonName,
                                                      value: season.seasonID,
                                                  }))
                                                : []
                                        }
                                        disabled={
                                            values.eventStatus !== 'Draft' &&
                                            values.eventStatus !==
                                                'Published' &&
                                            values.eventStatus !== undefined &&
                                            isAdmin === false
                                        }
                                    />
                                </FormGroup>
                            )}
                            {/* OpponentTeam */}
                            {values.eventType === 'Game' && (
                                <FormGroup
                                    label="Opponent"
                                    htmlFor="opponentTeam"
                                    error={getIn(
                                        errors,
                                        'game.opponentTeam.teamName'
                                    )}
                                    showError={
                                        !!getIn(
                                            errors,
                                            'game.opponentTeam.teamName'
                                        ) &&
                                        getIn(
                                            touched,
                                            'game.opponentTeam.teamName'
                                        )
                                    }
                                >
                                    <InputText
                                        id="opponentTeam"
                                        name="game.opponentTeam.teamName"
                                        onInput={handleChange}
                                        onBlur={handleBlur}
                                        value={
                                            values.game?.opponentTeam.teamName
                                        }
                                        required
                                    />
                                </FormGroup>
                            )}
                            {/* IsHomeTeam */}
                            {values.eventType === 'Game' && (
                                <FormGroup
                                    label="Home/Away"
                                    htmlFor="isHomeTeam"
                                    error={getIn(errors, 'game.isHomeTeam')}
                                    showError={
                                        !!getIn(errors, 'game.isHomeTeam') &&
                                        getIn(touched, 'game.isHomeTeam')
                                    }
                                >
                                    <SelectButton
                                        id="isHomeTeam"
                                        name="game.isHomeTeam"
                                        value={values.game?.isHomeTeam}
                                        options={[
                                            { label: 'Home', value: true },
                                            { label: 'Away', value: false },
                                        ]}
                                        onChange={handleChange}
                                        allowEmpty={false}
                                        required
                                    />
                                </FormGroup>
                            )}
                            {/* StartDateTime */}
                            <FormGroup
                                label="Date/Time"
                                htmlFor="startDateTime"
                                error={errors.startDateTime}
                                showError={
                                    !!errors.startDateTime &&
                                    touched.startDateTime
                                }
                            >
                                <InputText
                                    id="startDateTime"
                                    name="startDateTime"
                                    className={
                                        errors.startDateTime &&
                                        touched.startDateTime
                                            ? 'p-invalid'
                                            : ''
                                    }
                                    type="datetime-local"
                                    onInput={handleChange}
                                    onBlur={handleBlur}
                                    value={values.startDateTime}
                                    required
                                />
                            </FormGroup>
                            {/* EndDateTime */}
                            {values.eventType === 'Other' ||
                                (values.eventType === 'Training' && (
                                    <FormGroup
                                        label="End Date/Time"
                                        htmlFor="endDateTime"
                                        error={errors.endDateTime}
                                        showError={
                                            !!errors.endDateTime &&
                                            touched.endDateTime
                                        }
                                    >
                                        <InputText
                                            id="endDateTime"
                                            name="endDateTime"
                                            className={
                                                errors.endDateTime &&
                                                touched.endDateTime
                                                    ? 'p-invalid'
                                                    : ''
                                            }
                                            type="datetime-local"
                                            onInput={handleChange}
                                            onBlur={handleBlur}
                                            value={values.endDateTime}
                                            required
                                        />
                                    </FormGroup>
                                ))}
                            {/* Location */}
                            <FormGroup
                                label="Location (optional)"
                                htmlFor="location"
                                error={errors.location}
                                showError={
                                    !!errors.location && touched.location
                                }
                            >
                                <div className="p-inputgroup">
                                    <InputText
                                        ref={locationRef}
                                        id="location"
                                        name="location"
                                        type="text"
                                        value={
                                            values.location &&
                                            values.location.formattedAddress
                                                ? values.location
                                                      .formattedAddress
                                                : values.tempLocation
                                        }
                                        className={
                                            errors.location && touched.location
                                                ? 'p-invalid'
                                                : ''
                                        }
                                        onInput={(e) => {
                                            setFieldValue(
                                                'tempLocation',
                                                e.currentTarget.value
                                            );
                                        }}
                                        onBlur={(e) => {
                                            setFieldTouched('location');
                                        }}
                                        placeholder="Search address or venue"
                                        readOnly={!!values.location?.placeID}
                                    />
                                    {!!values.location?.placeID && (
                                        <RookieButton
                                            onClick={() => {
                                                setValues({
                                                    ...values,
                                                    location:
                                                        defaultEventValues.location,
                                                    tempLocation: '',
                                                });
                                            }}
                                            severity="secondary"
                                            icon="close"
                                        />
                                    )}
                                </div>
                            </FormGroup>
                            {/* LocationName */}
                            <FormGroup
                                htmlFor="location.locationExtraInfo"
                                label={
                                    values.eventType === 'Game'
                                        ? 'Playing Area Name (optional)'
                                        : values.eventType === 'Training'
                                        ? 'Training Area Name (optional)'
                                        : 'Event Area Name (optional)'
                                }
                            >
                                <InputText
                                    id="location.locationExtraInfo"
                                    name="location.locationExtraInfo"
                                    placeholder="Specific Event Location"
                                    onInput={handleChange}
                                    onBlur={handleBlur}
                                    value={values.location?.locationExtraInfo}
                                />
                            </FormGroup>
                            {/* Description */}
                            <FormGroup
                                label="Description (optional)"
                                htmlFor="description"
                            >
                                <InputTextarea
                                    id="description"
                                    name="description"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    placeholder="Add some notes"
                                    rows={3}
                                    value={values.description}
                                />
                            </FormGroup>
                        </FormFields>
                        <FormActions
                            end={
                                <RookieButton
                                    type="submit"
                                    disabled={
                                        isSubmitting ||
                                        !dirty ||
                                        !isEmpty(errors)
                                    }
                                    label={
                                        isSubmitting
                                            ? 'Loading...'
                                            : isCreate
                                            ? 'Create'
                                            : 'Update'
                                    }
                                />
                            }
                        />
                    </form>
                );
            }}
        </Formik>
    );
};

export default EventForm;
