import { useEffect, useMemo, useRef, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { unionBy, uniq, sortBy, orderBy } from 'lodash';

import { useGetEventsQuery } from '../../api/events';
import {
    useGetTeamOwnedSeasonsQuery,
    useGetTeamParticipatingSeasonsQuery,
} from '../../api/seasons';
import { useGetPlayersQuery } from '../../api/players';
import { useGetTeamSeasonPlusMinusReportQuery } from '../../api/reports';
import { useGetTeamQuery } from '../../api/teams';

import Icon from '../../components/Icon';
import PageHeader from '../../layout/PageHeader';

import playLogo from '../../assets/images/logos/rm-play-logo.png';
import { Image } from 'primereact/image';

import { Column, ColumnSortEvent } from 'primereact/column';
import { periodSuffix } from '../../util/helper';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { SelectButton } from 'primereact/selectbutton';
import { SelectItem } from 'primereact/selectitem';
import { Skeleton } from 'primereact/skeleton';
import { Toolbar } from 'primereact/toolbar';
import { Tooltip } from 'primereact/tooltip';

import { config, sportSpecificColumns } from './configTime';
import { toISOStringWithTimezone } from '../../util/helper';
import { defaultReportState } from '../reports/constants';
import { calculateTotals } from './helpers';
import { playerGroupCell, playerNameCell } from '../reports/DataTableCells';

import { Event } from '../../types/event';
import {
    ReportDataTypes,
    ReportState,
    StatConfig,
    TeamStat,
} from '../../types/reports';
import { InputSwitch } from 'primereact/inputswitch';
import ErrorDisplay from '../../components/ErrorDisplay';
import { BaseEntityType, ERROR_TYPES } from '../../types/common';
import RookieButton from '../../components/RookieButton';
import PageContainer from '../../layout/PageContainer';

import { Mixpanel } from '../../util/mixpanel';

interface Props {
    reportType: 'basic' | 'advanced' | 'expert';
}

const PlayerPlusMinusReport = ({ reportType }: Props) => {
    // Route Params
    const { teamID } = useParams();

    // Search Params
    const [searchParams] = useSearchParams();
    const eventParam = searchParams.get('eventID');
    const seasonParam = searchParams.get('seasonID');
    const categoryParam = searchParams.get('category');

    // State Hooks
    const [dataType, setDataType] = useState(ReportDataTypes.Total);
    const [season, setSeason] = useState<string | null>(seasonParam || null);
    const [event, setEvent] = useState<string>(eventParam || '');
    const [category, setCategory] = useState<string>(categoryParam || '');
    const [showInfo, setShowInfo] = useState<boolean>(false);
    const [hideEmptyColumns, setHideEmptyColumns] = useState<boolean>(true);
    const [playersCursor, setPlayersCursor] = useState<string>('');
    const [filter, setFilter] = useState(0); // for period filter
    // State hooks for player alert button

    const [plusMinusData, setPlusMinusData] =
        useState<ReportState<TeamStat[]>>(defaultReportState);

    // Cache busting ref
    const timestampRef = useRef(Date.now()).current;
    const dataTable = useRef<DataTable<any>>(null);

    const reportParams = {
        seasonID: season || '',
        teamID: teamID || '',
        sessionID: timestampRef,
    };

    // API Hooks
    const requestPlusMinusReport = useGetTeamSeasonPlusMinusReportQuery(
        reportParams,
        {
            skip: !season || !teamID,
        }
    );
    const requestPlusMinusData =
        reportType === 'expert' ? requestPlusMinusReport : null;

    const teamData = useGetTeamQuery(
        { teamID: teamID || '' },
        { skip: !teamID }
    );
    const teamSportID = teamData?.data?.data.entitySportID as string;

    // Request Plus Minus data
    useEffect(() => {
        const reportUrl = requestPlusMinusData?.data?.data.objectURL;

        if (reportUrl && requestPlusMinusData.isSuccess) {
            setPlusMinusData((prev) => ({
                ...prev,
                data: undefined,
                error: undefined,
                isError: false,
                isLoading: true,
                isUninitialized: false,
            }));

            //fetch report
            fetch(reportUrl)
                .then((response) => {
                    return response.json();
                })
                .then((data) => {
                    setPlusMinusData((prev) => ({
                        ...prev,
                        data,
                        isError: false,
                        isLoading: false,
                    }));
                })
                .catch((err) => {
                    setPlusMinusData((prev) => ({
                        ...prev,
                        error: err,
                        isError: true,
                        isLoading: false,
                    }));
                });
        } else {
            setPlusMinusData(defaultReportState);
        }
    }, [requestPlusMinusData]);

    const seasonOwnedData = useGetTeamOwnedSeasonsQuery(
        {
            teamID: teamID || '',
            cursor: '',
        },
        { skip: !teamID }
    );

    const seasonParticipatingData = useGetTeamParticipatingSeasonsQuery(
        {
            teamID: teamID || '',
            cursor: '',
        },
        { skip: !teamID }
    );

    const playerData = useGetPlayersQuery(
        {
            cursor: playersCursor,
            status: 'All',
            teamID: teamID || '',
        },
        {
            skip: !teamID,
        }
    );

    const eventData = useGetEventsQuery({
        entityType: BaseEntityType.teams,
        entityID: teamID || '',
        to: toISOStringWithTimezone(new Date()),
        limit: '50',
    });

    // Helpers
    const mergedSeasons = useMemo(() => {
        const ownedSeasons = seasonOwnedData?.data?.data;
        const participatingSeasons = seasonParticipatingData?.data?.data;

        return ownedSeasons && participatingSeasons
            ? unionBy(ownedSeasons, participatingSeasons, 'seasonID')
            : [];
    }, [seasonOwnedData, seasonParticipatingData]);

    // Auto paginate players
    useEffect(() => {
        const { data } = playerData;

        if (
            data?.lastEvaluatedKey &&
            data.lastEvaluatedKey.cursor &&
            data.lastEvaluatedKey.cursor !== playersCursor
        ) {
            setPlayersCursor(data.lastEvaluatedKey.cursor);
        }
    }, [playerData, playersCursor]);

    // Set the season to the first available season
    useEffect(() => {
        if (!season && mergedSeasons.length > 0) {
            setSeason(teamData?.data?.data.defaultSeasonID || '');
        }
    }, [mergedSeasons, season, teamData]);

    // Set data type to totals when filtering by a single event
    useEffect(() => {
        if (event !== '' && dataType === ReportDataTypes.Average) {
            setDataType(ReportDataTypes.Total);
        }
    }, [event, dataType]);

    // Inject player details into data
    useEffect(() => {
        const players = playerData?.data?.data;

        if (players && plusMinusData.data) {
            setPlusMinusData((prev) => ({
                ...prev,
                data:
                    prev.data &&
                    prev.data.map((stat: TeamStat) => ({
                        ...stat,
                        player: players.find(
                            (p) => p.playerID === stat.playerID
                        ),
                    })),
            }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [playerData, plusMinusData.isLoading]);

    // Filter Options
    const eventOptions = useMemo(() => {
        let options = [
            {
                label: 'Entire Season',
                value: '',
                error: false,
            },
        ];

        const availableGames = uniq(
            plusMinusData?.data?.map((val: TeamStat) => val.eventID)
        );

        if (eventData.data) {
            eventData.data.data.forEach((event: Event) => {
                options.push({
                    label: event.eventName,
                    value: event.eventID,
                    error: !availableGames.includes(event.eventID),
                });
            });
        }

        // Filter out options where error is true
        return options.filter((option) => !option.error);
    }, [plusMinusData, eventData]);

    const categoryOptions = useMemo(() => {
        let options: SelectItem[] = [
            {
                label: 'All Categories',
                value: '',
            },
        ];

        const availableCategories = uniq(
            (teamSportID ? sportSpecificColumns[teamSportID][reportType] : [])
                .map((val) => {
                    const stat = config[val as keyof typeof config];
                    return stat && stat.category;
                })
                .filter((val) => !!val)
        );

        availableCategories.forEach((cat) => {
            options.push({
                label: cat,
                value: cat,
            });
        });

        return options;
    }, [reportType, teamSportID]);

    const seasonOptions = useMemo(() => {
        let options = mergedSeasons
            ? mergedSeasons.map((season) => ({
                  label: season.seasonName,
                  value: season.seasonID,
              }))
            : [];

        return options;
    }, [mergedSeasons]);

    const dataTypeOptions = [
        {
            label: ReportDataTypes.Total,
            value: ReportDataTypes.Total,
        },
        {
            label: ReportDataTypes.Average,
            value: ReportDataTypes.Average,
        },
    ];

    const exportCSV = () => {
        dataTable.current && dataTable.current.exportCSV();

        Mixpanel.track('Export Report', {
            reportType: `${reportType} Cumulative Player Time`,
        });
    };

    const filteredData = useMemo(() => {
        let filtered = plusMinusData.data || [];

        filtered = filtered.filter(
            (item: TeamStat) => filter === 0 || item.period === filter
        );

        // Apply category filter
        if (event !== '') {
            filtered = filtered.filter(
                (item: TeamStat) => item.eventID === event
            );
        }

        const sorted = sortBy(
            filtered,
            (item: TeamStat) => item.player && Number(item.player.uniformNumber)
        );
        return sorted;
    }, [plusMinusData, event, filter]); // Recalculate when rawData or filter changes

    // Get total periods for the individual game
    let totalPeriods = 0;
    let periodFilteredData = plusMinusData.data || [];

    if (event !== '') {
        const filteredData = periodFilteredData.filter(
            (item: TeamStat) => item.eventID === event
        );

        totalPeriods = (filteredData as TeamStat[]).reduce(
            (maxPeriod, item: TeamStat) => {
                const periodAsNumber =
                    typeof item.period === 'number'
                        ? item.period
                        : Number(item.period);
                return periodAsNumber > maxPeriod ? periodAsNumber : maxPeriod;
            },
            0
        );
    }

    const periodOptions = useMemo(
        () =>
            Array.from({ length: totalPeriods }).map((u, i) => {
                const period = i + 1;
                const label = `${period}${periodSuffix(Number(period))}`;
                return {
                    label: label,
                    value: period,
                };
            }),
        [totalPeriods]
    );

    // Renders
    const leftToolbar = (
        <div className="p-button-group">
            <>
                <Dropdown
                    onChange={(e) => setSeason(e.value)}
                    value={season}
                    options={seasonOptions}
                />
                <Dropdown
                    onChange={(e) => setEvent(e.value)}
                    value={event}
                    options={eventOptions}
                    itemTemplate={(o) => {
                        return (
                            <div
                                style={{
                                    opacity: o.error ? 0.5 : 1,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                }}
                            >
                                {o.label} {o.error && <Icon name="error" />}
                            </div>
                        );
                    }}
                />
                {categoryOptions.length > 2 && (
                    <Dropdown
                        onChange={(e) => setCategory(e.value)}
                        value={category}
                        options={categoryOptions}
                    />
                )}
                {event && (
                    <SelectButton
                        value={filter}
                        multiple={false}
                        allowEmpty={false}
                        onChange={(e) => setFilter(e.value)}
                        options={[{ label: 'All', value: 0 }, ...periodOptions]}
                        disabled={event === ''}
                    />
                )}
            </>
        </div>
    );

    const rightToolbar = (
        <div className="p-button-group">
            {reportType !== 'basic' && (
                <div
                    className="p-button p-button-text p-button-plain"
                    style={{
                        display: 'inline-flex',
                        alignItems: 'center',
                        gap: '5px',
                        border: '1px solid #ced4da',
                        padding: '8px 15px',
                        borderRadius: '2px',
                        fontSize: '14px',
                        fontWeight: 'bold',
                    }}
                >
                    <label
                        style={{
                            margin: '0',
                            fontSize: '16px',
                            fontWeight: 'bold',
                            color: '#4f4f4f',
                        }}
                    >
                        Hide Empty Cols
                    </label>
                    <InputSwitch
                        checked={hideEmptyColumns}
                        onChange={(e) => setHideEmptyColumns(e.value)}
                    />
                </div>
            )}
            <SelectButton
                value={dataType}
                onChange={(e) => setDataType(e.value)}
                options={dataTypeOptions}
                disabled={event !== ''}
            />
            <RookieButton
                type="button"
                onClick={() => exportCSV()}
                label="Export CSV"
                severity="secondary"
            />
        </div>
    );

    const renderHeaderCell = (stat: StatConfig) => {
        const { description, hsArticle } = stat;

        return (
            <>
                <Tooltip
                    target={`.cell-${stat.shortName}`}
                    autoHide={false}
                    position="right"
                    onBeforeHide={() => setShowInfo(false)}
                >
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div>
                            <div>{stat.name}</div>
                            {showInfo && <div>{stat.description}</div>}
                        </div>
                        {hsArticle ? (
                            <RookieButton
                                data-beacon-article={hsArticle}
                                text={true}
                                icon="help"
                            />
                        ) : (
                            description && (
                                <RookieButton
                                    onClick={() => setShowInfo(!showInfo)}
                                    text={true}
                                    icon={showInfo ? 'close' : 'info'}
                                />
                            )
                        )}
                    </div>
                </Tooltip>
                {stat.shortName}
            </>
        );
    };

    const renderEmpty = () => {
        const msg = event
            ? "Can't load report for this event. The data may be corrupt. Try rebuilding your report or reach out to us via the question mark at the base of the page."
            : 'Unable to find report. Please try again later';
        const errorType = event
            ? ERROR_TYPES.somethingsWrong
            : ERROR_TYPES.notFound;

        return (
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <ErrorDisplay
                    alignment="middle"
                    title="No Report Found"
                    desc={msg}
                    errorType={errorType}
                    hasReturn={false}
                    proportion="compact"
                />
            </div>
        );
    };

    const calculatePlayedCount = (row: TeamStat) => {
        let value = null;
        if (plusMinusData && plusMinusData.data) {
            value = plusMinusData.data.filter(
                (o: TeamStat) => o.playerID === row.playerID && o.period === 1
            ).length;

            if (dataType === ReportDataTypes.Average) {
            }
        }

        return value;
    };

    const calculatedData = useMemo(() => {
        let calcData = calculateTotals(filteredData, 'playerID');

        if (dataType === ReportDataTypes.Average) {
            calcData = calcData.map((calc) => {
                let averages: any = {};
                const uniqueEventIDs = new Set(
                    filteredData
                        .filter((d: any) => calc.playerID === d.playerID) // Filter for the same playerID
                        .map((d: any) => d.eventID)
                );

                Object.keys(calc).forEach((k) => {
                    averages[k] =
                        typeof calc[k] === 'number'
                            ? Number((calc[k] / uniqueEventIDs.size).toFixed(1)) // Format to 1 decimal place
                            : calc[k];
                });

                return averages;
            });
        }

        return calcData;

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filteredData, playerData, dataType]);

    const tableFooter = (
        <div className="table-disclaimer">
            <span>Report generated by</span>{' '}
            <Image height="24px" src={playLogo} alt="Rookie Me Play" />
        </div>
    );

    const sortByGamesPlayed = ({ data, order }: ColumnSortEvent) =>
        order &&
        orderBy(
            data,
            (o) => calculatePlayedCount(o),
            order > 0 ? 'asc' : 'desc'
        );

    return (
        <PageContainer>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <PageHeader title="Plus Minus Report" />
                <RookieButton
                    style={{
                        backgroundColor: 'transparent',
                        border: 'none',
                        color: 'inherit',
                        opacity: 'inherit',
                        marginLeft: '-10px',
                        marginTop: '50px',
                    }}
                    text={true}
                    data-beacon-article="67240254e25b255d8d34b65c"
                    icon="help"
                />
            </div>
            <Toolbar start={leftToolbar} end={rightToolbar} />
            <div>
                <DataTable
                    ref={dataTable}
                    removableSort
                    showGridlines
                    value={
                        plusMinusData.isLoading || plusMinusData.isUninitialized
                            ? Array(5)
                            : calculatedData
                    }
                    emptyMessage={renderEmpty()}
                    scrollable
                    footer={tableFooter}
                    scrollHeight="100vh"
                    columnResizeMode="expand"
                    resizableColumns
                    exportFilename={`season-${reportType}-time-report`}
                    stripedRows={true}
                >
                    <Column
                        header="#"
                        align="center"
                        alignHeader="center"
                        sortable
                        field="player.uniformNumber"
                        frozen
                        headerStyle={{ backgroundColor: 'white' }}
                    />
                    <Column
                        frozen
                        sortable
                        header="Player"
                        field="player.lastName"
                        body={
                            playerData.isLoading ? <Skeleton /> : playerNameCell
                        }
                        exportable={false}
                        headerStyle={{ backgroundColor: 'white' }}
                    />
                    <Column
                        header="First Name"
                        field="player.firstName"
                        hidden={true}
                    />
                    <Column
                        header="Last Name"
                        field="player.lastName"
                        hidden={true}
                    />
                    <Column
                        sortable
                        header="Group"
                        field="player.colour"
                        body={
                            playerData.isLoading ? (
                                <Skeleton />
                            ) : (
                                playerGroupCell
                            )
                        }
                        headerStyle={{ backgroundColor: 'white' }}
                    />

                    {event === '' && (
                        <Column
                            align="center"
                            alignHeader="center"
                            sortable
                            sortFunction={sortByGamesPlayed}
                            body={(row) =>
                                !row || plusMinusData.isLoading ? (
                                    <Skeleton />
                                ) : (
                                    calculatePlayedCount(row)
                                )
                            }
                            header="Games"
                            headerStyle={{ backgroundColor: 'white' }}
                        />
                    )}

                    {(teamSportID
                        ? sportSpecificColumns[teamSportID][reportType]
                        : []
                    ).map((val: string) => {
                        const stat = config[val as keyof typeof config];

                        if (!stat) return null;

                        if (
                            hideEmptyColumns &&
                            (calculatedData as TeamStat[])
                        ) {
                            const emptyColumn: TeamStat[] =
                                calculatedData.filter((item: TeamStat) => {
                                    return item[val] && item[val] > 0;
                                });

                            if (emptyColumn.length <= 0 && val !== 'games') {
                                return null;
                            }
                        }

                        if (stat.category !== category && category !== '')
                            return null;

                        return [
                            <Column
                                field={val}
                                header={stat.name}
                                hidden={true}
                                exportable={
                                    stat.exportable === false ? false : true
                                }
                            />,
                            <Column
                                key={stat.shortName}
                                field={val}
                                headerStyle={{ backgroundColor: 'white' }}
                                align="center"
                                alignHeader="center"
                                sortable
                                header={renderHeaderCell(stat)}
                                headerClassName={`cell-${stat.shortName}`}
                                exportable={false}
                            />,
                        ];
                    })}
                </DataTable>
            </div>
        </PageContainer>
    );
};

export default PlayerPlusMinusReport;
