import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {
    Note,
    NoteFormData,
    NotePrivacy,
    NoteResponse,
    NotesResponse,
} from '../types/note';
import { apiEndpoints, prepareHeaders } from './apiEndpoints';
import { isEqual } from 'lodash';
import { EntityStructure, EntityType } from '../types/common';
import { generatePath } from 'react-router-dom';

export const notesApi = createApi({
    reducerPath: 'notesApi',
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_CLIENTAPI_URL?.replace(
            '{client}',
            'notes'
        ),
        prepareHeaders,
    }),
    tagTypes: ['Note'],
    endpoints: (builder) => ({
        getNotes: builder.query<
            NotesResponse,
            EntityStructure & {
                cursor?: string;
                notePrivacy?: NotePrivacy;
            }
        >({
            query: ({ entityID, entityType, ...params }) => ({
                url: generatePath(apiEndpoints.getNotes.url, {
                    entityID,
                    entityType,
                }),
                method: apiEndpoints.getNotes.method,
                params,
            }),
            providesTags: (result) => {
                return result && result.data.notes
                    ? [
                          ...result.data.notes.map(({ noteID }: Note) => ({
                              type: 'Note' as const,
                              noteID,
                          })),
                          'Note',
                      ]
                    : ['Note'];
            },
            serializeQueryArgs: ({
                endpointName,
                queryArgs: { cursor, ...args },
            }) => {
                return `${endpointName}(${JSON.stringify(args)})`;
            },
            merge: (currentCache, newItems, args) => {
                if (currentCache && newItems) {
                    if (
                        args.arg.cursor &&
                        currentCache?.data.lastEvaluatedKey !==
                            newItems?.data.lastEvaluatedKey
                    ) {
                        currentCache.data.notes = [
                            ...currentCache.data.notes,
                            ...newItems.data.notes,
                        ];

                        currentCache.data.lastEvaluatedKey.cursor =
                            newItems.data.lastEvaluatedKey.cursor;
                    } else {
                        currentCache.data.notes = newItems.data.notes;
                        currentCache.data.lastEvaluatedKey =
                            newItems.data.lastEvaluatedKey;
                    }
                }
            },
            forceRefetch({ currentArg, previousArg }) {
                return !isEqual(currentArg, previousArg);
            },
        }),
        getNoteSingle: builder.query<
            NoteResponse,
            EntityStructure & { noteID: string }
        >({
            query: ({ entityType, entityID, noteID }) => ({
                url: generatePath(apiEndpoints.getNote.url, {
                    entityType,
                    entityID,
                    noteID,
                }),
                method: apiEndpoints.getNote.method,
            }),
            providesTags: ['Note'],
        }),
        createNote: builder.mutation<
            NoteResponse,
            EntityStructure & NoteFormData
        >({
            query: ({ entityID, entityType, ...data }) => ({
                url: generatePath(apiEndpoints.createNote.url, {
                    entityID,
                    entityType,
                }),
                method: apiEndpoints.createNote.method,
                body: data,
            }),
            invalidatesTags: ['Note'],
        }),
        updateNote: builder.mutation<
            Note,
            EntityStructure & { noteID: string }
        >({
            query: ({ entityID, entityType, noteID, ...data }) => ({
                url: generatePath(apiEndpoints.updateNote.url, {
                    entityID,
                    entityType,
                    noteID,
                }),
                method: apiEndpoints.updateNote.method,
                body: data,
            }),
            invalidatesTags: ['Note'],
        }),
        deleteNote: builder.mutation<
            void,
            EntityStructure & { noteID: string }
        >({
            query: ({ entityID, entityType, noteID }) => ({
                url: generatePath(apiEndpoints.deleteNote.url, {
                    entityID,
                    entityType,
                    noteID,
                }),
                method: apiEndpoints.deleteNote.method,
            }),
            invalidatesTags: ['Note'],
        }),
        getNotesByNode: builder.query<
            NotesResponse,
            EntityStructure & {
                nodeID: string;
                nodeType: EntityType;
                cursor?: string;
                notePrivacy?: NotePrivacy;
            }
        >({
            query: ({ entityID, entityType, nodeID, nodeType, ...params }) => ({
                url: generatePath(apiEndpoints.getNotesByNode.url, {
                    entityID,
                    entityType,
                    nodeID,
                    nodeType,
                }),
                method: apiEndpoints.getNotesByNode.method,
                params,
            }),
            providesTags: (result) => {
                return result && result.data.notes
                    ? [
                          ...result.data.notes.map(({ noteID }: Note) => ({
                              type: 'Note' as const,
                              noteID,
                          })),
                          'Note',
                      ]
                    : ['Note'];
            },
            serializeQueryArgs: ({
                endpointName,
                queryArgs: { cursor, ...args },
            }) => {
                return `${endpointName}(${JSON.stringify(args)})`;
            },
            merge: (currentCache, newItems, args) => {
                if (currentCache && newItems) {
                    if (
                        args.arg.cursor &&
                        currentCache?.data.lastEvaluatedKey !==
                            newItems?.data.lastEvaluatedKey
                    ) {
                        currentCache.data.notes = [
                            ...currentCache.data.notes,
                            ...newItems.data.notes,
                        ];

                        currentCache.data.lastEvaluatedKey.cursor =
                            newItems.data.lastEvaluatedKey.cursor;
                    } else {
                        currentCache.data.notes = newItems.data.notes;
                        currentCache.data.lastEvaluatedKey =
                            newItems.data.lastEvaluatedKey;
                    }
                }
            },
            forceRefetch({ currentArg, previousArg }) {
                return !isEqual(currentArg, previousArg);
            },
        }),
        deleteNoteNode: builder.mutation<
            void,
            EntityStructure & {
                noteID: string;
                nodeID: string;
                nodeType: EntityType;
            }
        >({
            query: ({ entityID, entityType, nodeID, nodeType, noteID }) => ({
                url: generatePath(apiEndpoints.deleteNoteNode.url, {
                    entityID,
                    entityType,
                    noteID,
                    nodeType,
                    nodeID,
                }),
                method: apiEndpoints.deleteNoteNode.method,
            }),
            invalidatesTags: ['Note'],
        }),
    }),
});

export const {
    useGetNotesQuery,
    useLazyGetNotesQuery,
    useGetNoteSingleQuery,
    useCreateNoteMutation,
    useUpdateNoteMutation,
    useDeleteNoteMutation,
    useGetNotesByNodeQuery,
    useDeleteNoteNodeMutation,
} = notesApi;
