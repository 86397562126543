import { useParams } from 'react-router-dom';

import AddLicencesForm from './AddLicencesForm';
import AssignLicencesForm from './AssignLicencesForm';

import { Column } from 'primereact/column';
import { DataTable, DataTableValue } from 'primereact/datatable';
import { ProgressBar } from 'primereact/progressbar';
import { Sidebar } from 'primereact/sidebar';
import { Skeleton } from 'primereact/skeleton';

import PageHeader from '../../layout/PageHeader';
import PageContainer from '../../layout/PageContainer';
import EntityName from '../../components/EntityName';
import RookieButton from '../../components/RookieButton';

import { Roles } from '../../types/roles';
import { Route } from '../../types/route';
import { User } from '../../types/user';
import { LicenceGroupInfo } from '../../types/licences';
import { Team } from '../../types/team';
import { BaseEntityType } from '../../types/common';

interface Props {
    roles: Roles;
    route: Route;
    user: User;
    licenceGroups: LicenceGroupInfo[];
    isLoading: boolean;
    showAddModal: boolean;
    showAssignModal: boolean;
    activeLicenceGroup?: string;
    onAssignLicences?: (licenceGroupID: string, teams: Team[]) => void;
    onShowAssignModal: (licenceGroupID: string) => void;
    onLicenceGroupClick: (licenceGroup: DataTableValue) => void;
    onCloseAssignModal: () => void;
    onShowAddModal: () => void;
    onCloseAddModal: () => void;
    permissions: {
        canCreate: boolean;
        canDelete: boolean;
        canEdit: boolean;
        canView: boolean;
    };
}

const OrganisationLicencesView = (props: Props) => {
    const {
        activeLicenceGroup,
        licenceGroups,
        onAssignLicences,
        showAssignModal,
        onShowAssignModal,
        onCloseAssignModal,
        onLicenceGroupClick,
        isLoading,
        showAddModal,
        onShowAddModal,
        onCloseAddModal,
        permissions,
    } = props;
    const { organisationID } = useParams();

    return (
        <PageContainer>
            <PageHeader
                title="Licences"
                actions={
                    permissions.canCreate
                        ? [
                              {
                                  label: 'Add Licences',
                                  command: () => onShowAddModal(),
                              },
                          ]
                        : []
                }
            />
            {isLoading ? (
                <div>Loading...</div>
            ) : (
                <DataTable
                    value={isLoading ? Array(3) : licenceGroups}
                    onRowClick={(e) =>
                        permissions.canView && onLicenceGroupClick(e.data)
                    }
                    columnResizeMode="expand"
                    resizableColumns
                >
                    <Column
                        field="productTitle"
                        header="Name"
                        body={isLoading && <Skeleton />}
                    />
                    <Column
                        field="billingPeriod" // This should match the actual key in your data object
                        header="Type"
                        body={(rowData) => {
                            if (isLoading) {
                                return <Skeleton />;
                            }
                            // Replace 'licenceTitle' with the actual key you need to access the data
                            return rowData.billingPeriod === 'year'
                                ? 'Annual'
                                : 'Monthly';
                        }}
                    />
                    <Column
                        field="qtyAvailable"
                        header="Available"
                        body={isLoading && <Skeleton />}
                    />
                    <Column
                        field="qtyAssigned"
                        header="Assigned"
                        body={(data) =>
                            isLoading ? (
                                <Skeleton />
                            ) : (
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: '10px',
                                    }}
                                >
                                    <ProgressBar
                                        style={{ flex: '1', height: '8px' }}
                                        showValue={false}
                                        value={
                                            (data.qtyAssigned / data.qtyTotal) *
                                            100
                                        }
                                    />
                                    <span>
                                        {data.qtyAssigned}/{data.qtyTotal}
                                    </span>
                                </div>
                            )
                        }
                    />
                    <Column
                        field="status"
                        header="Status"
                        body={isLoading && <Skeleton />}
                    />
                    <Column
                        field="owner"
                        header="Owner"
                        body={(data) =>
                            isLoading ? (
                                <Skeleton />
                            ) : (
                                <EntityName
                                    entityID={
                                        data.originalEntityID
                                            ? data.originalEntityID
                                            : data.entityID
                                    }
                                    entityType={
                                        data.originalEntityType
                                            ? data.originalEntityType
                                            : data.entityType
                                    }
                                />
                            )
                        }
                    />
                    <Column
                        body={(data) => {
                            if (isLoading) {
                                return <Skeleton />;
                            } else {
                                return data.qtyTotal === data.qtyAssigned ? (
                                    <RookieButton
                                        severity="secondary"
                                        label="View"
                                        onClick={() =>
                                            onLicenceGroupClick(data)
                                        }
                                    />
                                ) : permissions.canEdit ? (
                                    <RookieButton
                                        label="Assign"
                                        onClick={() =>
                                            onShowAssignModal(data.id)
                                        }
                                    />
                                ) : null;
                            }
                        }}
                        style={{ textAlign: 'right' }}
                    />
                </DataTable>
            )}
            <Sidebar
                header="Assign Licence"
                onHide={() => onCloseAssignModal()}
                visible={showAssignModal}
                position="right"
            >
                {activeLicenceGroup && organisationID && (
                    <AssignLicencesForm
                        licenceGroupID={activeLicenceGroup}
                        organisationID={organisationID}
                        onCancel={() => onCloseAssignModal()}
                        onSubmit={onAssignLicences}
                    />
                )}
            </Sidebar>
            <Sidebar
                header="Add Licence"
                onHide={() => onCloseAddModal()}
                visible={showAddModal}
                position="right"
            >
                {organisationID && (
                    <AddLicencesForm
                        entityID={organisationID}
                        entityType={BaseEntityType.organisations}
                        onUpdateSuccess={() => {
                            window.location.reload();
                        }}
                    />
                )}
            </Sidebar>
        </PageContainer>
    );
};

export default OrganisationLicencesView;
