import { PaginatedResponse, Response } from './api';
import { EntityType } from './common';
import { Game, GameSettings } from './game';

export enum EventType {
    Game = 'Game',
    //Testing = 'Testing',
    Training = 'Training',
    Other = 'Other',
}

export enum EventStatus {
    Draft = 'Draft',
    Published = 'Published',
    Live = 'Live',
    Complete = 'Complete',
    Postponed = 'Postponed',
    Archived = 'Archived',
}

export enum EventVisibility {
    Public = 'Public',
    Host = 'Host',
    Participants = 'Participants',
}

// Location Types

export enum LocationCategory {
    Used = 'Used',
    Saved = 'Saved',
}

export interface Location {
    category: LocationCategory;
    locationExtraInfo?: string;
    streetNumber: string;
    route: string;
    formattedAddress: string;
    address?: string;
    location?: string;
    placeID?: string;
    latitude?: string | number;
    longitude?: string | number;
    locationName?: string;
    suburb?: string;
    state?: string;
    postCode?: string | number;
    city?: string;
    country?: string;
    eventID?: string;
}

// RSVP Types

export enum EventUserResponse {
    Yes = 'Yes',
    No = 'No',
    Maybe = 'Maybe',
    NoReply = 'NoReply',
}

export enum EventUserAttendance {
    Attended = 'Attended',
    Absent = 'Absent',
}

export enum AttendeeRole {
    Owner = 'Owner',
    Admin = 'Admin',
    Staff = 'Staff',
    Attendee = 'Attendee',
}

export interface Attendee {
    attendeeID: string;
    eventID: string;
    roles: string[];
    userID: string;
}

export interface InvitedUser {
    userID: string;
    name: string;
    role: string;
    response: string;
    createdAt: string;
    createdBy: string;
    gameRoles: string[];
    eventRoles: string[];
    inviteeID: string;
}

export interface InvitedExternals {
    email: string;
    token: string;
    name: string;
    role: string;
    createdAt: string;
    createdBy: string;
    inviteeID: string;
}

export interface Event {
    calendarNodeID: string;
    calendarNodeType: EntityType;
    createdAt: string;
    description: string;
    eventID: string;
    gameID?: string;
    eventName: string;
    eventOwnerID: string;
    eventStatus: EventStatus;
    eventType: EventType;
    eventVisibility: EventVisibility;
    eventAttendees: {
        attendees: Attendee[];
        eventID: string;
    };
    eventInvitees: {
        eventID: string;
        invitedExternals: InvitedExternals[];
        invitees: InvitedUser[];
    };
    lastEdited: string;
    lastEditedBy: string;
    location: Location;
    parentID: string;
    rootID: string;
    startDateTime: string;
    startedAt: string;
    ticketRequired: boolean;
    // Generic event properties
    arrivalDateTime?: string;
    endDateTime?: string;
    // Game event properties
    game?: Game;
    gameDetails?: Game;
    isHomeTeam?: boolean;
    seasonID?: string;
    // For grouping events
    dateGroup?: string;
}

export interface EventFormData {
    eventID?: string;
    eventType?: EventType;
    eventName?: string;
    eventStatus?: EventStatus;
    startDateTime?: string;
    description?: string;
    location?: Location;
    locationExtraInfo?: string;

    // Generic event properties
    arrivalDateTime?: string;
    endDateTime?: string;

    // Game event properties
    seasonID?: string;
    game?: {
        isHomeTeam: boolean;
        opponentTeam: {
            teamName: string;
        };
        settings?: GameSettings;
    };
    tempLocation?: string;
}

export interface EventInviteeForm {
    userID: string;
    gameRoles?: string[];
    eventRoles?: string[];
}

export interface CalendarEvent
    extends Omit<Event, 'startDateTime' | 'arrivalDateTime' | 'endDateTime'> {
    startDateTime: Date;
    arrivalDateTime?: Date;
    endDateTime?: Date;
}

export type EventDates = Pick<
    Event,
    'startDateTime' | 'endDateTime' | 'arrivalDateTime'
>;

export interface EventParams {
    cursor: string;
}

export type EventResponse = Response<Event>;
export type EventsResponse = PaginatedResponse<Event[]>;
